<!-- HISTORY: has this.log(msg, showAlert)
   V250324.1: Set appItems from the user's preferences instead of appList().
   V250318.1: Forced the appEndpoint field for the Generic App to ensure it only works with the push mechanism.
   V250314.1: Fixed a few bugs + Removed all extra fields related to inbound.
   V250313.1: Added logic to handle Generic App (appCode #702) + Added associationType field.
   V241112.1: In update action, made settings updateable as well.
   V240327.2: Got appItems based on their onProduction property and the logged-in user +
      Applied policies for the creating and editing actions.
   V240208.1: Fixed the bug that showed uneditable fields (by adding "!currItem._id &&" condition).
   V240126.1: Moved appItems to cs-apps.js and loaded, registered and consumed components dynamically.
   V231121.1: Removed all sample apps + Changed the Wallet Pass app's code + Removed error-message from textarea cause it wasn't set.
   V231109.2: Removed consumerCode and appEndpoint from the textfield and expanded rows.
   V231109.1: Added Wallet Pass app + Added consumerCode and appEndpoint to the apps v-model.
   V230222.1: Placed the app dropdown 1st + Changed the icon + Performed validate() on dialog open.
   V230221.1: 1st version/release.
-->
<!--TODO:
- Make sure that ENDPOINT_PATTERN works fine for all cases
- Pass associationType to the apps UI (<component>)
- Make save btn in edit disabled until the 1st change.
-->
<!-- BUSINESS LOGIC:
   {
      name: Joi.string().min(5).max(250).required(),
      appCode: Joi.string().valid(process.env.APP_CODES.split(',').join(',')).required(),
      appType: Joi.string().valid('outbound','inbound', 'both').required(),
      inboundAssociatedField: Joi.string(),
      inboundRecordLookup: Joi.string(), //{ filter:{ cell: "{to}" } , findValueIn: "body|query" }
      inboundRecordLookupScope: Joi.string(), //this is offer filter without aggregation which will be merged with inboundRecordLookup
      consumerCode: Joi.string().min(32).max(36).required(),
      instruction: Joi.object().required(),
      appEndpoint:
      associationType: Joi.array().required().min(1).max(3)
   }
-->
<template>
<v-container fluid class="px-3 py-3">
   <v-card>
      <v-card-title class="pl-2 pb-2">
         <h1 class="title font-weight-bold grey--text darken-4 pl-2" style="color:#757575 !important">
            <v-icon class="pr-2 pb-2">connect_without_contact</v-icon>
            <span>Actions</span>
         </h1>
         <div class="flex-grow-1"></div>
         <!--TODO: <bt-sendgrid-users
            :debug="debug"
            :isActualEndpoint="isActualEndpoint"
         >
         </bt-sendgrid-users> -->
         <v-btn v-if="canCreate"
            x-small
            class="mr-2 mt-1"
            color="gray darken-1"
            :disabled="loadingItems"
            @click="newItemClicked()"
         >NEW ACTION
            <v-icon right dark>add</v-icon>
         </v-btn>
      </v-card-title>
      <v-card-text class="py-0">
         <bt-filter-wrapper-with-panel
            :closed-on-load="true"
            :fields="searchFields"
            :included-tabs="['standard']"
            :is-admin="jwt.pa"
            :max="searchFields.length"
            :preselected-fields="[]"
            :should-init="shouldInitFilterDefiner"
            :std-field-values="searchFieldsValues"
            v-model="filter"
            @filter-change="filterChanged"
         ></bt-filter-wrapper-with-panel>
      </v-card-text>
      <v-card-text class="pt-2">
         <v-data-table dense fixed-header show-expand single-expand
            class="elevation-1"
            item-key="name"
            :footer-props="{
               itemsPerPageOptions: [5, 10, 20],
               showFirstLastPage: true
            }"
            :headers="headers"
            :hide-default-footer="itemsCount <= 5"
            :items="items"
            :items-per-page="5"
            :loading="loadingItems"
            :loading-text="$t('loading-text')"
            :no-data-text="$t('no-data-text', { value: 'items' })"
            :no-results-text="$t('no-results-text', { value: 'items' })"
            :options.sync="options"
            :search="search"
            :server-items-length="itemsCount"
         >
            <template v-slot:[`item.appCode`]="{ item }">
               {{ getAppText(item.appCode) }}
            </template>
            <template v-slot:[`item.appType`]="{ item }">
               <v-icon>{{getAppTypeIcon(item.appType)}}</v-icon>
               <span> {{ getAppTypeDesc(item.appCode, item.appType) }}</span>
            </template>
            <template v-slot:[`item.associationType`]="{ item }">
               {{ item.associationType.join(', ') }}
            </template>
            <template v-slot:[`item.createdAt`]="{ item }">
               {{ formatDate(item.createdAt, true) }}
            </template>
            <template v-slot:[`item.action`]="{ item }">
               <v-icon v-if="canEdit"
                  small
                  @click="editItemClicked(item)"
               >edit</v-icon>
               <!-- <v-icon v-if="canDelete"
                  small
                  @click="deleteItem(item)"
               >delete</v-icon> -->
            </template>
            <template v-slot:expanded-item="{ item }">
               <td colspan="7" class="py-2" valign="top" dense>
                  <ul>
                     <li>
                        <span class="expanded-header">Instruction: </span>
                        <span class="expanded-content">{{item.instruction}}</span>
                     </li>
                     <li v-if="item.appEndpoint.trim()">
                        <span class="expanded-header">App Endpoint: </span>
                        <span class="expanded-content">{{item.appEndpoint}}</span>
                     </li>
                     <!-- <li v-if="item.appType != 'outbound'">
                        <span class="expanded-header">Inbound Associated Field: </span>
                        <span class="expanded-content">{{item.inboundAssociatedField}}</span>
                     </li>
                     <li v-if="item.appType != 'outbound'">
                        <span class="expanded-header">Inbound Record Lookup: </span>
                        <span class="expanded-content">{{item.inboundRecordLookup}}</span>
                     </li>
                     <li v-if="item.appType != 'outbound'">
                        <span class="expanded-header">Inbound Record Lookup Scope: </span>
                        <span class="expanded-content">{{item.inboundRecordLookupScope}}</span>
                     </li> -->
                     <li>
                        <span class="expanded-header">ID: </span>
                        <span class="expanded-content">{{item._id}}</span>
                     </li>
                  </ul>
               </td>
            </template>
         </v-data-table>
      </v-card-text>
   </v-card>

   <v-dialog v-if="currItem"
      no-click-animation persistent
      max-width="960px"
      v-model="mainDialog"
   >
      <v-form lazy-validation
         ref="mainForm"
         v-model="isMainFormValid"
      >
         <v-card flat class="px-3">
            <v-card-title class="title grey--text darken-4 font-weight-bold pb-2">
               {{currItem._id ? `Edit Action '${currItem.name}'` : 'Create a New Action'}}
            </v-card-title>
            <v-card-text
               class="pb-0"
               :loading="loadingNewItem"
            >
               <v-row>
                  <v-col xs="12" sm="12" :md="appColSize" class="py-0">
                     <v-autocomplete persistent-hint required return-object
                        ref="app"
                        placeholder="select an action..."
                        :disabled="Boolean(currItem._id)"
                        hint="* App"
                        :items="appItems"
                        :rules="[rules.required]"
                        v-model="formData.app"
                        @change="appChanged"
                     ></v-autocomplete>
                  </v-col>
                  <v-col v-if="formData.app.value" xs="12" sm="12" md="5" class="py-0">
                     <v-autocomplete multiple persistent-hint required small-chips deletable-chips
                        ref="associationType"
                        autocomplete="off"
                        :class="formData.app.associationType.length ? 'pt-3' : 'pt-4'"
                        placeholder="select an association type..."
                        hint="* Association Type"
                        :items="associationTypeItems"
                        :rules="[rules.required]"
                        v-model="formData.app.associationType"
                        @change="associationTypeChanged"
                     ></v-autocomplete>
                  </v-col>
                  <v-col v-if="hasAssociation" xs="12" sm="12" md="3" class="py-0">
                     <v-select disabled persistent-hint required
                        ref="appType"
                        autocomplete="off"
                        placeholder="select an app type..."
                        hint="* App Type"
                        :items="appTypeItems"
                        :rules="[rules.required]"
                        v-model="formData.app.type"
                        @change="appTypeChanged"
                     ></v-select>
                  </v-col>
               </v-row>

               <v-row v-if="hasAssociation" class="pt-3">
                  <v-col xs="12" sm="12" :md="nameColSize" class="py-0">
                     <v-text-field counter persistent-hint required
                        ref="name"
                        autocomplete="off"
                        placeholder="enter a name or description with 5 to 150 chars"
                        hint="* Name"
                        :rules="[rules.required, rules.nameLen, rules.duplicateName]"
                        v-model="formData.name"
                     ></v-text-field>
                  </v-col>
                  <v-col v-if="isGenericApp && !currItem._id" xs="12" sm="12" md="6" class="py-0">
                     <v-text-field counter persistent-hint required
                        ref="consumerCode"
                        autocomplete="off"
                        hint="* Consumer Code"
                        :placeholder="consumerCodePH"
                        :minlength="32"
                        :maxlength="36"
                        :rules="consumerCodeRules"
                        v-model="formData.consumerCode"
                     ></v-text-field>
                  </v-col>
               </v-row>

               <!-- <v-row v-if="hasAssociation && !currItem._id && formData?.app?.type && formData?.app?.type != 'outbound'">
                  <v-col xs="12" sm="12" md="12" class="pt-0 pb-2">
                     <v-text-field counter persistent-hint required
                        ref="inboundAssociatedField"
                        autocomplete="off"
                        placeholder="specify the associated field name"
                        hint="* Inbound Associated Field"
                        :rules="[rules.required]"
                        v-model="formData.inboundAssociatedField"
                     ></v-text-field>
                  </v-col>
               </v-row>
               <v-row v-if="hasAssociation && !currItem._id && formData?.app?.type && formData?.app?.type != 'outbound'">
                  <v-col xs="12" sm="12" md="12" class="pt-5 pb-0">
                     <v-textarea dense outlined persistent-hint
                        readonly
                        class="py-0 my-0 caption"
                        ref="scope"
                        rows="4"
                        placeholder="to be implemented... (filter's $match value)"
                        hint="* Inbound Record Lookup Scope"
                        v-model="formData.inboundRecordLookupScope"
                     ></v-textarea>
                  </v-col>
               </v-row> -->
               <v-row v-if="!isGenericApp && hasAssociation && isComponentLoaded">
                  <v-col xs="12" sm="12" md="12" class="py-0 pr-0">
                     <v-card flat class="pt-2 pb-0">
                        <v-card-title class="mx-0 my-0 px-0 py-0">
                           <div class="flex-grow-1"></div>
                           <component
                              :is="appComponent"
                              :debug="debug"
                              :is-actual-endpoint="isActualEndpoint"
                              :app-name="formData.app.text"
                              v-model="formData.appInstructions"
                              @change="appInstructionsChanged"
                           ></component>
                        </v-card-title>
                     </v-card>
                  </v-col>
               </v-row>
               <v-row v-show="hasAssociation && strAppInstructionsPH" :class="isGenericApp ? 'pt-5 mt-5' : ''">
                  <v-col xs="12" sm="12" md="12" class="py-0">
                        <!-- :error-messages="errMsg" -->
                     <v-textarea dense outlined persistent-hint required
                        class="py-0 my-0 caption"
                        ref="strAppInstructions"
                        rows="4"
                        hint="* Instruction"
                        :loading="!isComponentLoaded"
                        :placeholder="strAppInstructionsPH"
                        :readonly="!isGenericApp"
                        :rules="[rules.required, rules.validJson]"
                        v-model="formData.strAppInstructions"
                     ></v-textarea>
                  </v-col>
               </v-row>

               <v-row v-if="isGenericApp && hasAssociation && formData.app.type != 'inbound'">
                  <v-col xs="12" sm="12" md="12" class="pt-0 pb-3"
                  >
                     <v-text-field persistent-hint required
                        ref="appEndpoint"
                        autocomplete="off"
                        hint="* App Endpoint"
                        :placeholder="appEndpointPH"
                        :rules="[rules.required, rules.endpoint]"
                        v-model="formData.appEndpoint"
                     ></v-text-field>
                  </v-col>
               </v-row>
            </v-card-text>

            <v-card-actions>
               <div class="flex-grow-1"></div>
               <v-btn text small
                  class="px-0"
                  color="blue darken-1"
                  @click="closeMainDialog"
               >Cancel</v-btn>
               <v-btn text small
                  class="px-0 mx-0"
                  color="blue darken-1"
                  :disabled="!formData.app.value || !isMainFormValid"
                  @click="saveItem"
               >Save</v-btn>
            </v-card-actions>
         </v-card>
      </v-form>
   </v-dialog>

   <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
   </v-overlay>
</v-container>
</template>

<script>
import BtFilterWrapperWithPanel from './BtFilterWrapperWithPanel.vue';
import { APIService } from '../services/cs-api-service.js';
import { format, parseISO } from "date-fns";
import { developersAccess, appList } from '../mixins/cs-apps.js';

class FormData {
   constructor(initVal ={}, app ={}) {
      this.name = initVal.name || '';

      this.app = {};
      Object.keys(this.app).forEach(key => delete this.app[key]);
      this.app = JSON.parse(JSON.stringify(app));

      this.app.associationType = initVal.associationType || [];

      this.appInstructions = {};
      Object.keys(this.appInstructions).forEach(key => delete this.appInstructions[key]);
      if (initVal.instruction) {
         this.appInstructions.instruction = initVal.instruction;
         this.strAppInstructions = JSON.stringify(initVal.instruction);
      } else {
         this.strAppInstructions = '';
      }
     
      this.appEndpoint = initVal.appEndpoint?.trim() || '';

      // if (initVal.consumerCode)
      //    this.appInstructions.consumerCode = initVal.consumerCode;
            
      // this.inboundAssociatedField = initVal.inboundAssociatedField || '';
      // this.inboundRecordLookupScope = initVal.inboundRecordLookupScope || '';
   }
}

const NAME = 'CsActions';
const VERSION = 'V250324.1';
const ENDPOINT_PATTERN = /^(https?:\/\/)?([\w.-]+)(:\d{1,5})?(\/[\w\-./?%&=]*)?$/;

export default {
   name: NAME,

   components: {
      //TODO: BtSendgridUsers,
      BtFilterWrapperWithPanel
   },

   props: {
      debug: {
         type: Boolean,
         default: false
      },

      isActualEndpoint: {
         type: Boolean,
         default: true
      }
   },

   data() {
      return {
         rules: {
            required: value => !!value || "Value is required!",
            nameLen: value => (value?.trim().length >= 5 && value?.trim().length <= 150) || "Value should have 5 to 150 chars!",
            consumerCodeLen: value => (value?.trim().length >= 32 && value?.trim().length <= 36) || "Value should have 32 to 36 chars!",
            duplicateName: value => {
               const action = this.items.find(item => item.name.toLowerCase() === value.toLowerCase().trim());
               if (action)
                  return action._id === this.currItem._id || 'Value is duplicate!';
               else return true;
            },
            validJson: value => {
               try {
                  const obj = JSON.parse(value);
                  return (
                     obj && 
                     typeof obj === "object" && 
                     !Array.isArray(obj) && 
                     Object.keys(obj).length > 0 &&  // Ensure it has properties
                     Object.values(obj).some(val => val !== null && val !== '' && val !== undefined) // Ensure at least one non-empty value
                  ) || "Invalid JSON: Must have at least one property with a value.";
               } catch (error) {
                  return error.toString();
               }
            },
            endpoint: value => ENDPOINT_PATTERN.test(value?.trim()) || "Value is invalid!"
         },
         headers: [
            { text: 'Name', value: 'name', align: 'left', sortable: true },
            { text: 'App', value: 'appCode', align: 'left', sortable: true },
            { text: 'App Type', value: 'appType', align: 'left', sortable: true },
            { text: 'Association Types', value: 'associationType', align: 'left', sortable: true },
            { text: 'Author', value: 'author', align: 'left', sortable: true },
            { text: 'Creation Date', value: 'createdAt', align: 'left', sortable: true },
            { text: 'Actions', value: 'action', align: 'right', sortable: false }
         ],
         searchFields: [
            { text: 'Name', value: 'name', type: 'string', isIndexed: true },
            { text: 'App Code', value: 'appCode', type: 'string', isIndexed: true },
            { text: 'App Type', value: 'appType', type: 'string', isIndexed: true },
            { text: 'Author', value: 'author', type: 'string', isIndexed: true  }
         ],
         appTypeItems: [
            { text: 'Outbound', value: 'outbound' },
            { text: 'Inbound', value: 'inbound' },
            { text: 'Inbound/Outbound', value: 'both' }
         ],
         appItems: [],
         jwt: {},
         apiService: null,
         filter: {
            standard: [{ $match: {} }]
         },
         searchFieldsValues: null,
         itemsCount: 0,
         items: [],
         options: {},
         search: '',
         loadingItems: true,
         loadingNewItem: false,
         mainDialog: false,
         isMainFormValid: false,
         shouldInitFilterDefiner: false,
         currItem: null,
         formData: {},
         overlay: false,
         appComponent: null,
         isComponentLoaded: false,
         strAppInstructionsPH: '',
         associationTypeItems: [],
         hasAssociation: false,
         consumerCodePH: '',
         consumerCodeRules: [],
         appEndpointPH: ''
      }
   },

   computed: {
      token() {
         return this.$store.getters.token;
      },

      canCreate() {
         return this.$store.getters.user.policies && this.$store.getters.user.policies.includes('contact-action-create');
      },

      canEdit() {
         return this.$store.getters.user.policies && this.$store.getters.user.policies.includes('contact-action-update');
      },

      canDelete() {
         return this.$store.getters.user.policies && this.$store.getters.user.policies.includes('contact-action-delete');
      },

      appColSize() {
         return this.formData.app.value ? 4 : 12;
      },

      nameColSize() {
         return this.isGenericApp && !this.currItem?._id ? 6 : 12;
      },

      canManageSendgridUsers() {
         return (this.isActualEndpoint && this.jwt.email === 'btalebpour@mindfireinc.com') || this.jwt.email === 'daver+webinar@mindfireinc.com';
      },

      isGenericApp() {
         return this.formData.app?.value === '702';
      },

      // componentPath() {
      //    // Return the path to the dynamically loaded component
      //    return `./${this.appComponent}.vue`;
      // }      
   },

   watch: {
      token() {
         this.init();
         this.nextAction();
      },

      options: {
         handler (val) {
            if (val.sortBy.length > 0) {
               const sort = {};
               sort[val.sortBy[0]] = val.sortDesc[0] ? -1 : 1;
               this.filter.sort = sort;
            }
            this.getItems();
         }
      },

      // componentPath(val) {
      //    alert(`${this.currentComponent}\n${val}`)
      //    // Dynamically load the component when the path changes
      //    // import(`./${this.currentComponent}.vue`)
      //    import(val)
      //    .then(module => {
      //       alert('in then');
      //       // Register the component dynamically
      //       this.$options.components[this.currentComponent] = module.default;
      //       alert('after $options');
      //    })
      //    .catch(error => {
      //       alert('Error loading component:', JSON.stringify(error));
      //    });
      // },

      //    async componentPath(val) {
      //    // Dynamically load the component when the path changes
      //    try {
      //      alert('watch');
      //       console.log('Before dynamic import');
      //       const module = await import(`./${this.currentComponent}.vue`);
      //       console.log('After dynamic import', module);
      //    //    const module = () => import(val);
      //    //   alert('module='+module.default);
      //    // //   const module2 = await import("./BtSendgridEmail.vue");
      //    //   const module2 = import("./BtSendgridEmail.vue");
      //    //   alert('moduleee2='+module2.default);
      //    //   // Register the component dynamically
      //    console.log('$options before', this.$options.components)
      //      this.$options.components[this.currentComponent] = module.default;
      //      this.isComponentLoaded = true;
      //    console.log('$options after', this.$options.components)
      //    //   alert('options');
      //    } catch (error) {
      //      console.error('Error loading component:', error);
      //      // Handle the error as needed, e.g., show a fallback component or log the error
      //       alert('Error loading componentttt:', JSON.stringify(error));
      //    }
      //  }
   },

   methods: {
      log(msg, showAlert) {
         if (this.debug) {
            console.log(`-----${NAME} ${VERSION} says => ${msg}`);
            if (showAlert)
               alert(`-----${NAME} ${VERSION} says => ${msg}`);
         }
      },

      logout() {
         this.$router.push('/');
      },

      async init() {
         try {
            this.items = [];

            if (this.token) {
               // user, email, pa, pu, aid, paid, exp
               this.jwt = JSON.parse(Buffer.from(this.token.split('.')[1], 'base64'));
               this.log(`in ${NAME}.init(): jwt=${JSON.stringify(this.jwt)}`);
               this.apiService = new APIService(this.jwt, this.token, this.debug, this.isActualEndpoint);
               await this.getItemsCount();

               if (developersAccess().includes(this.jwt.email))
                  this.appItems = appList(); //.filter(app => app.hasExportAssociation);
               else {
                  // this.appItems = appList().filter(app => app.onProduction);
                  if (this.$store.getters.user.preferences?.apps?.length) {
                     const availableApps = this.$store.getters.user.preferences.apps;
                     this.appItems = appList().filter(app => availableApps.includes(app.value))
                  } else
                     this.appItems = [];
               }

               this.searchFieldsValues = {
                  appCode: this.appItems,
                  appType: this.appTypeItems
               };

               this.shouldInitFilterDefiner = true;
            } else {
               this.jwt = {};
               this.appItems = [];
               this.searchFieldsValues = null;
            }
         } catch (error) {
            alert('Exception while parsing token: ' + error.message);
         }
      },

      async getItemsCount() {
         this.loadingItems = true;
         let result = await this.apiService.getActionsCount(this.filter);
         if (result.logout)
            this.logout();

         this.itemsCount = result.message ? 0 : result.data;
         this.loadingItems = false;
      },

      async getItems() {
         this.loadingItems = true;
         let result = await this.apiService.getActions(this.filter, this.options.itemsPerPage, this.options.page);
         if (result.logout)
            this.logout();
         else if (result.message)
            this.items = [];
         else {
            this.items = result.data;
            this.$forceUpdate();
         }
         this.loadingItems = false;
      },

      getAppText(appCode) {
         const app = this.appItems.find(item => item.value === appCode);
         return app ? app.text : `Unknown (${appCode})`;
      },

      getAppTypeDesc(appCode, appType) {
         const app = this.appItems.find(item => item.value === appCode);
         if (app)
            return app.typeDesc || this.appTypeItems.find(t => t.value === appType).text;
         else
            return `Unknown (${appCode}/${appType})`;
      },

      getAppTypeIcon(appType) {
         switch (appType) {
            case 'outbound':
               return 'output';
            case 'inbound':
               return 'input';
            case 'both':
               return 'sync_alt';
            default:
               return '';
         }
      },

      formatDate(date, withTime) {
         if (date) {
            // alert('in formatDate(): date='+date+'\nwithTime=' + withTime + '\nparseISO='+parseISO(date));
            const formattedDate = format(parseISO(date), 'M/d/yyyy h:mm:ss a');
            if (withTime) return formattedDate;
            else return formattedDate.split(' ')[0];
         }
      },

      async filterChanged(filter) {
         // alert('in filterChanged(): filter=' + JSON.stringify(filter) + '\noptions=' + JSON.stringify(this.options));
         this.filter = filter;
         await this.getItemsCount();
         this.nextAction();
      },

      async nextAction() {
         const currOptions = JSON.stringify(this.options);
         const newOptions = JSON.parse(currOptions);
         newOptions.page = 1;
         if (JSON.stringify(newOptions) === currOptions)
            await this.getItems();
         else
            this.options = newOptions;
      },

      async newItemClicked() {
         this.currItem = {};
         this.formData = new FormData();
         this.consumerCodePH = 'enter a consumer code with 32 to 36 chars';
         this.consumerCodeRules = [
            this.rules.required,
            this.rules.consumerCodeLen
         ];
         this.mainDialog = true;
         setTimeout(() => {
            this.$refs?.app?.focus();
         }, 10);
      },

      async editItemClicked(item) {
         // alert(`in editItemClicked(): item=${JSON.stringify(item)}`);
         this.currItem = JSON.parse(JSON.stringify(item));
         const currApp = this.appItems.find(item => item.value === this.currItem.appCode);
         this.formData = new FormData(this.currItem, currApp);
         this.consumerCodePH = 'value cannot be modified';
         this.consumerCodeRules = [];
         this.mainDialog = true;
         this.appChanged(currApp);
      },

      async appChanged(val) {
         // alert('in appChanged(): val=' + JSON.stringify(val));
         try {
            this.strAppInstructionsPH = '';
            this.isComponentLoaded = false;
            if (val.componentName) {
               this.appTypeChanged(val.type);
               this.strAppInstructionsPH = 'click on the menu icon (3-dot) above this box to configure the action...';
               this.appComponent = val.componentName;
               if (!this.$options.components[this.appComponent]) {
                  const module = await import(`./${this.appComponent}.vue`);
                  this.$options.components[this.appComponent] = module.default;
               }
            } else {
               this.strAppInstructionsPH = "paste the action's instruction here...";
               this.appTypeChanged('both');
            }

            this.isComponentLoaded = true;
            //TODO: it gave error: Error in beforeMount hook: "TypeError: Cannot read properties of undefined (reading 'find')"
            const this2 = this;
            setTimeout(() => {
               // this2.$refs?.mainForm?.validate();
               this2.$refs?.associationType?.focus();
            }, 10);
         } catch (error) {
            // this.strAppInstructionsPH = 'Error loading component: ' + JSON.stringify(error.code) + ' - ' + error;
            this.strAppInstructionsPH = '' + error;
            console.error('Error loading component:', error);
         }
      },

      appTypeChanged(val) {
         const associations = [];
         switch (val) {
            case 'outbound':
               if (this.formData.app.hasExportAssociation)
                  associations.push('Export');
               if (this.formData.app.hasTriggerAssociation)
                  associations.push('Trigger');
               break;
            case 'inbound':
               associations.push('Inbound');
               break;
            case 'both':
               if (this.formData.app.hasExportAssociation)
                  associations.push('Export');
               if (this.formData.app.hasTriggerAssociation)
                  associations.push('Trigger');
               associations.push('Inbound');
               break;
         }

         this.associationTypeItems = [];
         associations.forEach(association => {
            this.associationTypeItems.push({ text: association, value: association.toLowerCase() });
         });
         
         if (!this.currItem._id) {
            this.formData.app.associationType = [];
            if (this.associationTypeItems.length === 1) {
               this.formData.app.associationType.push(this.associationTypeItems[0].value);
            }
         }

         this.associationTypeChanged(this.formData.app.associationType);
      },

      associationTypeChanged(val) {
         if (val.length) {
            this.hasAssociation = true;
            if (val.includes('inbound'))
               this.formData.app.type = val.length === 1 ? 'inbound' : 'both';
            else
               this.formData.app.type = 'outbound';

            if (this.formData.app.type != 'inbound') {
               const ph = [];
               if (val.includes('export')) ph.push('export');
               if (val.includes('trigger')) ph.push('trigger');
               this.appEndpointPH = `specify a valid endpoint to POST the ${ph.join('/')} actions to`;
            }
         } else {
            this.hasAssociation = false;
            this.formData.app.type = '';
         }

         setTimeout(() => {
            this.$refs?.name?.focus();
         }, 10);
      },

      appInstructionsChanged(val) {
         // alert(`in appInstructionsChanged(): val=${JSON.stringify(val)}`);

         this.formData.appInstructions = val;
         this.formData.strAppInstructions = JSON.stringify(val.instruction);

         // const keys = Object.keys(val);
         // if (keys && keys.contalength === 3 && 
         //    keys.instruction && typeof keys.instruction === 'object' && Object.keys(keys)) {
         //    this.formData.appInstructions = val;
         //    this.errMsg = '';
         // } else
         //    this.errMsg = "Invalid Settings: One or more of 'instruction', 'consumerCode', or 'appEndpoint' is missing!";
      },

      async saveItem() {
         if (!this.$refs.mainForm.validate()) return;

         this.loadingNewItem = true;

         const actionData = {
            name: this.formData.name.trim(),
            associationType: this.formData.app.associationType
            // appEndpoint: this.formData.appInstructions.appEndpoint,
            // instruction: this.formData.appInstructions.instruction,
            // consumerCode: this.formData.appInstructions.consumerCode
         };

         let consumerCode, appEndpoint;
         if (this.isGenericApp) {
            actionData.instruction = JSON.parse(this.formData.strAppInstructions);
            consumerCode = this.formData.consumerCode;
            appEndpoint = this.formData.app.type === 'inbound' ? ' ' : this.formData.appEndpoint.trim();
         } else {
            actionData.instruction = this.formData.appInstructions.instruction;
            consumerCode = this.formData.appInstructions.consumerCode;
            appEndpoint = this.formData.appInstructions.appEndpoint;
         }

         // if (this.formData.app.type != 'outbound') {
         //    actionData.inboundAssociatedField = this.formData.inboundAssociatedField.trim();
         //    actionData.inboundRecordLookup = JSON.stringify(this.formData.app.inboundRecordLookup);
         //    actionData.inboundRecordLookupScope = this.formData.inboundRecordLookup;   //this is offer filter without aggregation which will be merged with inboundRecordLookup;
         // }

         let result;
         if (this.currItem._id) {
            if (this.formData.app.type != this.currItem.appType)
               actionData.appType = this.formData.app.type;

            if (appEndpoint != this.currItem.appEndpoint)
               actionData.appEndpoint = appEndpoint;

            result = await this.apiService.updateAction(this.currItem, actionData);
            if (result.logout)
               this.logout();
            else if (!result.message)
               this.$emit('snackbar-event', `The '${this.formData.name}' Action was updated.`);
         } else {
            actionData.appCode = this.formData.app.value;
            actionData.appType = this.formData.app.type;
            actionData.consumerCode = consumerCode;
            actionData.appEndpoint = appEndpoint

            result = await this.apiService.createAction(actionData);
            if (result.logout)
               this.logout();
            else if (!result.message) {
               this.$emit('snackbar-event', `The new '${this.formData.name}' Action was created.`);
               this.itemsCount++;
            }
         }

         if (!result.message) {
            await this.nextAction();
            this.closeMainDialog();
            this.loadingNewItem = false;
         }
      },

      closeMainDialog() {
         this.mainDialog = false;
         this.currItem = null;
         this.hasAssociation = false;
      },

      async deleteItem(item) {
         if (confirm(`Are you sure to delete '${item.name}'?`)) {
            this.loadingItems = true;
            this.overlay = true;
            const result = await this.apiService.deleteAction(item._id);
            if (result.logout)
               this.logout();
            else if (!result.message) {
               this.$emit('snackbar-event', `The '${item.name}' Action was deleted.`);
               this.itemsCount--;
               await this.nextAction();
            }
            this.overlay = false;
            this.loadingItems = false;
         }
      }
   },

   created() {
      this.init();
   }
}
</script>

<style scoped>
.v-text-field input {
   padding: 4px 0 8px;
}
.expanded-header {
   font-style: italic;
   font-weight: bold;
}
</style>
<template>
<!-- TODO: -->
<!-- HISTORY: no-version
   V250311.1: 1st version/release.
-->
<v-expansion-panels multiple focusable
   class="px-4 py-4"
   v-model="panels"
>
   <v-expansion-panel readonly
      class="px-0"
   >
      <v-expansion-panel-header hide-actions
         class="py-0 font-weight-bold"
      >SFTP Settings</v-expansion-panel-header>
      <v-expansion-panel-content class="px-0">
         <v-card flat tile>
            <v-card-text class="px-0 pt-5 pb-0">
               <v-form lazy-validation
                  ref="sftpForm"
                  v-model="isSftpFormValid"
               >
                  <v-row class="py-0">
                     <v-col cols="12" xs="12" sm="12" md="6" lg="6" class="pt-1 pb-0">
                        <v-text-field autofocus clearable dense persistent-hint
                           ref="host"
                           autocomplete="off"
                           label="Host"
                           v-model="formData.host"
                           @keyup="hostChanged(formData.host)"
                           @click:clear="hostCleared"
                        ></v-text-field>
                     </v-col>
                     <v-col v-if="formData.host" class="pt-1"
                        cols="12" xs="12" sm="12" md="4" lg="4"
                     >
                        <v-text-field dense persistent-hint
                           ref="folder"
                           autocomplete="off"
                           label="Folder"
                           v-model="formData.sftpPath"
                           @keyup="formDataChanged"
                        ></v-text-field>
                     </v-col>
                     <v-col v-if="formData.host" class="pt-1"
                        cols="12" xs="12" sm="12" md="2" lg="2"
                     >
                        <v-text-field dense persistent-hint
                           ref="port"
                           autocomplete="off"
                           label="Port"
                           type="number"
                           min="1"
                           v-model="formData.port"
                           @keyup="formDataChanged"
                        ></v-text-field>
                     </v-col>
                  </v-row>
                  <v-row v-if="formData.host" class="py-0">
                     <v-col cols="12" xs="12" sm="12" md="6" lg="6" class="py-0">
                        <v-text-field dense persistent-hint
                           ref="sftpUsername"
                           autocomplete="off"
                           label="* User Name"
                           :rules="[rules.required]"
                           v-model="formData.sftpUsername"
                           @keyup="formDataChanged"
                        ></v-text-field>
                     </v-col>
                     <v-col cols="12" xs="12" sm="12" md="6" lg="6" class="py-0">
                        <v-text-field counter dense persistent-hint
                           ref="sftpPass"
                           autocomplete="off"
                           label="* Password"
                           :type="showPassword ? 'text' : 'password'"
                           :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                           :rules="[rules.required]"
                           @click:append="showPassword = !showPassword"
                           v-model="formData.sftpPass"
                           @keyup="formDataChanged"
                        ></v-text-field>
                     </v-col>
                  </v-row>
                  <v-row v-if="formData.host" class="pb-0">
                     <v-col cols="12" class="pb-0">
                        <v-switch dense
                           class="mx-0 mt-2 mb-0 pb-0"
                           ref="includePgp"
                           label="Include PGP"
                           :disabled="!isSftpFormValid"
                           v-model="formData.includePgp"
                           @change="includePgpChanged"
                        ></v-switch>
                     </v-col>
                  </v-row>
               </v-form>
            </v-card-text>
         </v-card>
      </v-expansion-panel-content>
   </v-expansion-panel>                                       

   <v-expansion-panel v-show="formData.includePgp"
      readonly
      class="px-0 pb-0"
   >
      <v-expansion-panel-header hide-actions
         class="py-0 font-weight-bold"
      >PGP Settings</v-expansion-panel-header>
      <v-expansion-panel-content class="px-0">
         <v-card flat tile>
            <v-card-text class="px-0 pt-5 pb-2">
               <v-form lazy-validation
                  ref="pgpForm"
                  v-model="isPgpFormValid"
               >
                  <v-row class="py-0">
                     <v-col xs="12" sm="12" md="6">
                        <v-text-field clearable dense persistent-hint
                           ref="passphrase"
                           label="Passphrase"
                           :disabled="!formData.host || !isSftpFormValid"
                           v-model="formData.passphrase"
                        ></v-text-field>
                     </v-col>
                     <v-col v-if="formData.passphrase"
                        xs="12" sm="12" md="6" class="pt-5 pb-0">
                        <v-btn block
                           color="primary"
                           :disabled="!isSftpFormValid"
                           @click="generatePgpKeys"
                        >Generate Key</v-btn>
                     </v-col>
                  </v-row>

                  <v-row v-if="formData.passphrase" v-show="formData.publicKey"
                     class="py-0"
                  >
                     <v-col xs="12" sm="12" md="6" class="py-0">
                        <v-textarea dense hide-details
                           class="py-0 my-0 caption"
                           rows="4"
                           v-model="formData.publicKey"
                        ></v-textarea>
                     </v-col>
                     <v-col xs="12" sm="12" md="6" class="pt-0 pb-0">
                        <v-btn v-if="isClipboard"
                           block
                           class="caption"
                           color="primary"
                           :hint="copyToClipboardHint"
                           @click="copyToClipboard"
                        >
                           <v-icon dark left class="pl-1">content_copy</v-icon>
                           <span>{{copyToClipboardHint}}</span>
                        </v-btn>
                     </v-col>
                  </v-row>
               </v-form>
            </v-card-text>
         </v-card>
      </v-expansion-panel-content>
   </v-expansion-panel>
</v-expansion-panels>
</template>

<script>
import { generateKey } from 'openpgp';

class FormData {
   constructor(initData) {
      this.host = initData?.sftpCredentials?.host || '';
      this.port = initData?.sftpCredentials?.port || 22;
      this.sftpUsername = initData?.sftpCredentials?.username || '';
      this.sftpPass = initData?.sftpCredentials?.password || '';

      this.sftpPath = initData?.sftpPath || '';

      this.passphrase = initData?.pgpCredentials?.passPhrase || '';
      this.privateKey = initData?.pgpCredentials?.privateKey || '';
      this.publicKey = '';

      this.includePgp = this.privateKey ? true : false;
   }
}

const NAME = "BtSftpAndPgp";

export default {
   name: NAME,

   components: {},

   props: {
      value: {
         type: Object
      },

      isEdit: {
         type: Boolean,
         default: false
      },

      email: {
         type: String
      }
   },

   data() {
      return {
         rules: {
            required: value => !!value || "Value is required!",
         },
         formData: new FormData(),
         panels: [0, 1],
         isSftpFormValid: true,
         isPgpFormValid: true,
         showPassword: false,
         isClipboard: false,
         copyToClipboardHint: ''
      }
   },

   computed: { },

   watch: {
      value: {
         handler (val) {
            // alert('in watch: value=' + JSON.stringify(val) + '\nlen=' + Object.keys(val)?.length);
            if (!Object.keys(val)?.length && !this.isEdit) {
               this.formData = new FormData();
               setTimeout(() => {
                  this.$refs?.host?.focus();
               }, 10);
            }
         }
      },

      isEdit: {
         immediate: true,
         handler (val) {
            if (val) {
               this.formData = new FormData(this.value);
               setTimeout(() => {
                  this.$refs?.host?.focus();
               }, 10);
            }
         }
      }
   },

   methods: {
      async hostChanged(val) {
         this.formDataChanged();
         if (val) {
            this.isSftpFormValid = this.$refs.sftpForm.validate();
         }
      },

      hostCleared() {
         this.formData.host = '';
         this.hostChanged(this.formData.host);
      },

      includePgpChanged(val) {
         // if (!val)   //TODO: I commented this
            this.formDataChanged();

         if (val) {
            setTimeout(() => {
               this.$refs.passphrase.focus();
            }, 10);
         }
      },

      async generatePgpKeys() {
         // revocationCertificate also can be added to the end of const.
         const { privateKey, publicKey } = await generateKey({
            // ECC keys (smaller and faster to generate):
            type: 'ecc',
            curve: 'p256', // ECC curve name, defaults to curve25519
            // RSA keys (increased compatibility):
            // type: 'rsa',
            // rsaBits: 4096, // RSA key size (defaults to 4096 bits)
            userIDs: [{ name: this.email.split('@')[0], email: this.email }], // you can pass multiple user IDs
            passphrase: this.formData.passphrase, // protects the private key
            format: 'armored' // output key format, defaults to 'armored' (other options: 'binary' or 'object')
         });

         this.formData.privateKey = privateKey;
         this.formData.publicKey = publicKey;
         this.copyToClipboardHint = 'Copy Public Key to the Clipboard';
         this.formDataChanged();
      },

      async copyToClipboard() {
         await navigator.clipboard.writeText(this.formData.publicKey);
         this.copyToClipboardHint = 'Public Key was copied to the clipboard at ' + new Date().toString().split(' ')[4];
      },

      formDataChanged() {
         this.isSftpFormValid = this.$refs.sftpForm.validate();
         this.isPgpFormValid = this.$refs.pgpForm.validate();

         let hasSftp = false;
         let isSftpValid = true;
         const sftp = {};

         if (this.formData.host) {
            hasSftp = true;

            if (!this.isSftpFormValid || !this.isPgpFormValid)
               isSftpValid = false;

            sftp.sftpCredentials = {
               host: this.formData.host,
               username: this.formData.sftpUsername,
               password: this.formData.sftpPass
            };

            if (this.formData.port)
               sftp.sftpCredentials.port = +this.formData.port;

            if (this.formData.sftpPath)
               sftp.sftpPath = this.formData.sftpPath;

            if (this.formData.includePgp) {
               sftp.pgpCredentials = {
                  passPhrase: this.formData.passphrase,
                  privateKey: this.formData.privateKey
               };
            }
         }

         this.$emit('input', sftp);
         this.$emit('change', hasSftp, isSftpValid);
      }
   },

   created() {
      this.isClipboard = navigator.clipboard;
   }

}
</script>

<style scoped>
.expanded-header {
   font-style: italic;
   font-weight: bold;
}
.v-expansion-panel-header, .v-expansion-panel-header--active {
   padding: 0 16px;
   min-height: 40px !important;
}
div >>> .v-expansion-panel-content__wrap {
   padding: 8px 16px 16px !important;
}
</style>
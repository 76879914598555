<template>
  <nav>
    <v-app-bar v-if="isLoggedIn" dense :clipped-left="false" app>
        <v-app-bar-nav-icon v-if="isLoggedIn" @click="drawer = !drawer" class="grey--text" style="margin-left:7px"></v-app-bar-nav-icon>
        
        <v-flex xs12 md8 class="hidden-sm-and-down">
          <v-toolbar-title  class="text-uppercase grey--text">
            <img src="../assets/mindfire_logo.png" style="margin-top:9px;margin-left:7px" height="30" alt="MindFireInc">
            <!-- <span class="font-weight-light pl-6">MindFire</span>
            <span> BI</span> -->
          </v-toolbar-title>
        </v-flex>

        <v-flex xs12 md4  v-if="isLoggedIn">
          <div align="right" style="margin-top:20px;margin-right:15px">
            <v-autocomplete
              item-text="Value"
              item-value="Key"
              v-model="selectedAccount"
              :items="accounts"
              @change="accountChanged"
              label="Account"
              prepend-icon="home"
              single-line
            ></v-autocomplete>
          </div>
        </v-flex>
        <!-- <v-flex xs4 md2  v-if="isLoggedIn"  >
          <div align="right">
            <v-btn  color="grey  lighten-3" @click="logout">
              <span>Log Out</span>
              <v-icon right>exit_to_app</v-icon>
            </v-btn>
          </div>
        </v-flex> -->
    </v-app-bar>

    <v-navigation-drawer app v-model="drawer" width="220" mini-variant-width="60" class="grey pt-0" color="teal" :expand-on-hover="expandOnHover" :mini-variant="miniVariant" v-if="isLoggedIn">
      <!-- <v-layout column align-center>
        <v-flex class="mt-5">
          <v-avatar size="150">
            <img style="cursor:pointer" :title="user.email" class="text-lg-center" @click="showImageUpload=false" :src="photoUrl">
          </v-avatar>
          <p class="white--text subheading mt-1">
            <br/>
            <span class="font-weight-light pl-7">MINDFIRE</span>
            <span> BI</span>
          </p>
        </v-flex>
        <v-flex class="mt-4 mb-3">
          
          <ImageUpload :dialog="showImageUpload" @imageUploaded="showImageUpload=false" @imageUploadCanceled="showImageUpload=false" />
        </v-flex>
      </v-layout> -->
      <v-list dense nav class="py-0">
          
        <v-list-item  :class="miniVariant">
          <v-list-item-avatar>
            <img :src="photoUrl" @click="openUserProfile" style="cursor:pointer;">
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title style="color:black">{{portalSettings.company}}</v-list-item-title>
            <v-list-item-subtitle style="color:gray">{{portalSettings.product}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider class="py-0"></v-divider>
        
        <v-list-item  v-for="link in links" :key="link.text" router :to="link.route"  v-show="hasAccess(link.route)" > <!---->
          <v-list-item-action>
            <v-icon class="gray--text">{{ link.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="gray--text">{{ link.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item @click="help" v-show="this.showMenu('campaigns')" >
          <v-list-item-action>
            <v-icon class="gray--text">help</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="gray--text">Help</v-list-item-title>
          </v-list-item-content>
        </v-list-item> 

        <v-list-item router to="/" @click="logout">
          <v-list-item-action>
            <v-icon class="gray--text">exit_to_app</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="gray--text">Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>        
      </v-list>

      <v-divider></v-divider>
      
      <v-btn icon @click.stop="miniVariant = !miniVariant">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <v-icon>more_horiz</v-icon>
      </v-btn>
    </v-navigation-drawer>

  </nav>
</template>

<script>

export default {
  computed : {
    
    user: function(){
      return this.$store.getters.user;
    },
    photoUrl : function(){ 
      //console.log('user: ', this.$store.getters.user)
      if(this.$store.getters.photoURL)
        return this.$store.getters.photoURL;
      else
        return "/assets/dummy-photo-unknown.jpeg";
    },
    isLoggedIn : function(){ 
      //console.log(this.$store.getters.isLoggedIn);
      return this.$store.getters.isLoggedIn;
    },
    globalSelectedAccount : function(){ 
      return this.$store.getters.account;
    },
    accounts: function() {
      //return this.$store.getters.accounts;
      if(this.$store.getters.accounts)
      {

        function compare(a, b) {
          if (a.Value.toLowerCase() < b.Value.toLowerCase())
            return -1;
          if (a.Value.toLowerCase() > b.Value.toLowerCase())
            return 1;
          return 0;
        }

        let sorted = JSON.parse(JSON.stringify(this.$store.getters.accounts));
        return sorted.sort(compare);
      }
      else{
        this.logout();
        return null;
      }

    }
  },
  data() {
    return {
      portalSettings: {},
      expandOnHover: false,
      miniVariant: true,
      drawer: true,
      showImageUpload: false,
      links: [
        //{ icon: 'home', text: 'Home', route: '/home' },
        { icon: 'dashboard', text: 'Dashboards', route: '/dashboards' },
        // { icon: 'device_hub', text: 'Marketing Workflows', route: '/module/workflows' },
        { icon: 'business_center', text: 'Campaigns', route: '/module/objectives' },
        { icon: 'wallpaper', text: 'Assets', route: '/module/assets' },
        { icon: 'connect_without_contact', text: 'CRM', route: '/module/crm' },
        { icon: 'psychology', text: 'Market Intel', route: '/module/market-intel' },
        { icon: 'cloud_download', text: 'Downloads', route: '/downloads' },
        { icon: 'list', text: 'Data Services', route: '/contact-service' },
        { icon: 'qr_code', text: 'CSV Processor', route: '/csv-processor' },
        { icon: 'vpn_key', text: 'Identity & Access', route: '/iam' },
        { icon: 'settings', text: 'Settings', route: '/module/settings' },
        { icon: 'engineering', text: 'Administration', route: '/admin-service' }
        
        
        // { icon: 'dashboard', text: 'BI', route: '/bi' },
        // { icon: 'folder', text: 'My Projects', route: '/projects' },
        //{ icon: 'group', text: 'Team', route: '/team' },
      ],
      snackbar: false,
      selectedAccount: 0
    }
  },
  methods: {
    hasAccess: function(route){
      if(route.startsWith('/iam')){
        const show =  (this.user.isPowerAdmin == true || this.user.isPowerUser == true)

        return show;
      }
      else if(route.startsWith('/contact-service') ){ //&& (this.user.isPowerAdmin == true)
        const show = this.showMenu('contactservice');
 
        return show;
      }
      else if(route.startsWith('/module/workflows')){
        if(this.hasUserPermission('no-campaigns')){
          return false;
        }

        const show = this.showMenu('campaigns');

        return show;
      }
      else if(route.startsWith('/module/objectives')){
        if(this.hasUserPermission('no-campaigns')){
          return false;
        }

        const show = this.showMenu('campaigns');
 
        return show;
      }
      else if(route.startsWith('/module/assets')){
        if(this.hasUserPermission('no-assets')){
          return false;
        }

        const show = this.showMenu('assets');

        return show;
      }
      else if(route.startsWith('/module/crm')){
        if(this.hasUserPermission('no-crm')){
          return false;
        }

        const show = this.showMenu('crm');

        return show;
      }
      else if(route.startsWith('/module/market-intel')){
        if(this.hasUserPermission('no-marketintel')){
          return false;
        }

        const show = this.showMenu('marketintel');

        return show;
      }
      else if(route.startsWith('/module/settings')){
        if(this.hasUserPermission('no-settings')){
          return false;
        }

        const show = this.showMenu('settings');

        return show;
      }
      else if(route.startsWith('/dashboards')){
        const show = this.showMenu('dashboards');

        return show;
      }
      else if(route.startsWith('/csv-processor')){
        if(this.hasUserPermission('no-csvprocessor')){
          return false;
        }

        const show = this.showMenu('csvprocessor');
 
        return show;
      }
      else if(route.startsWith('/downloads')){
        const show = this.showMenu('dashboards');

        return show;
      }
      else if(route.startsWith('/home')){
        const show = this.showMenu('home');

        return show;
      }
      else if(route.startsWith('/admin-service')){
        return this.user.isAdminUser == true;
      }

      return false;

      

    },
    openUserProfile: function(){
      this.$router.push('/module/user-profile');
    },
    setThePortalSettings: async function(){
      
        this.portalSettings = this.$store.getters.deployment;
        //this.portalSettings = await (await fetch(process.env.BASE_URL + "settings.json")).json();

    },
    hasUserPermission: function(policyName){
        //alert(this.$store.getters.user.policies);
        if(this.$store.getters.user.policies.includes(policyName)){
            return true;
        }

        return false;
    },
    showMenu: function(menuName){
      const email = this.$store.getters.user.email;
      const preferences = this.$store.getters.user.preferences;

      if(!preferences.menus.hasOwnProperty(menuName))
        return false;

      if(preferences.menus[menuName].length === 0)
        return true;

      if(preferences.menus[menuName][0] == "-1"){
        return !preferences.menus[menuName].includes(email.trim().toLowerCase());
      } else {
        return preferences.menus[menuName].includes(email.trim().toLowerCase());
      }
    },
    help: function() {
      window.open(this.portalSettings.helpURL, "_blank");
    },
    logout: function(){

      this.$store.dispatch('logout')
      .then(() => {
        this.$router.push('/');
        if(gapi)
          gapi.auth2.getAuthInstance().signOut();
      })
    },
    accountChanged: async function(){
      if(!this.selectedAccount)
        return;
        
      await this.$store.dispatch('changeAccount', this.selectedAccount)
      await this.$store.dispatch('loginWithAccount', this.$store.getters.account);
      

      if(this.hasAccess(this.$route.path)){
        this.$router.push(this.$route.path);
      } else {
        this.$router.push('/dashboards');
      }

      //this.$router.push('/dashboards');

    }
  },
  created: async function() {
    await this.setThePortalSettings();
    this.selectedAccount = this.globalSelectedAccount; //this.$store.getters.account;
  },
  watch: {
    globalSelectedAccount: {
      handler(val){
       this.selectedAccount = val;
      },
      deep: true
    }
  }
}
</script>

<style scoped>
  .v-navigation-drawer__content, .pt-0{
    background-color:#e6e6e6 !important;
    /* width: 80px !important; */
  }
  .v-list .v-list-item--active {
    color: #ff6633;
    background-color: #f6f6f6;
  }
  .v-list--nav .v-list-item, .v-list--nav .v-list-item:before {
    border-radius: 0px;
  }
  .v-list-item--link:before {
    background-color: #f6f6f6;
  }
  .v-list--nav {
      padding-left: 0;
      padding-right: 0;
  }
  .v-navigation-drawer__border {
    width: 0px !important;
  } 
  .v-list-item__avatar {
    margin-bottom: 2px;
    margin-top: 2px;
  }

</style>

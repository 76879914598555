<!-- 
   WHAT: To set users access/permissions.
   WHO: Originally by Rohit Dutta
   HISTORY: no-version
   V250324.1: Added Apps tab.
   V240717.1: Added Landing Page tab + Added Csv Processor (csvprocessor) menu.
   V240318.1: Added BI Dashboards and List Service Dashboards tabs.
   04/13/22(B0.3): Added Home (home) menu + Moved convertParentToInternal() call to the watch for landingRoute.
   03/15/22(B0.2): Added List Service (contactservice) menu.
   09/14/21(B0.1): 1st version/release.
-->
<template>
   <v-container fluid class="px-3 py-3">
      <v-card class="px-2">
         <v-toolbar flat color="white">
            <h1
               class="title font-weight-bold grey--text darken-4 pl-2"
               style="color: #757575 !important"
            >
               <v-icon class="pr-1">room_preferences</v-icon>
               <span>Preferences</span>
            </h1>
         </v-toolbar>
         <v-tabs dark
            class="elevation-2"
            background-color="grey lighten-2 accent-4"
            slider-color="black"
            v-model="tab"
         >
            <v-tab class="black--text">Menus</v-tab>
            <v-tab class="black--text">Session</v-tab>
            <v-tab class="black--text">BI Dashboards</v-tab>
            <v-tab class="black--text">List Service Dashboards</v-tab>
            <v-tab class="black--text">Landing Page</v-tab>
            <v-tab class="black--text">Apps</v-tab>

            <v-tabs-items v-model="tab">
               <v-tab-item>
                  <v-card v-for="(item, name) in menus" :key="name" class="my-2">
                     <v-card-title class="py-0 px-5">
                        <!-- Object.keys() is needed to convert property name to index value:
                              Object.keys() puts all properties in an array, and indexOf finds the
                              index of the property name i -->
                        <v-switch
                           v-model="enableValue[Object.keys(menus).indexOf(name)]"
                           :label="convertToTitle(name)"
                           :disabled="isDisabled(name)"
                        ></v-switch>
                     </v-card-title>
                     <v-card-text
                        class="pr-0 pb-0 py-0"
                        v-if="enableValue[Object.keys(menus).indexOf(name)] === true"
                     >
                        <v-form v-model="valid">
                           <v-textarea
                              :disabled="isDisabled(name)"
                              v-model="textInput[Object.keys(menus).indexOf(name)]"
                              label="Enter emails separated by commas (NO SPACES)"
                              :rows="textAreaRows"
                              class="py-0 pr-4"
                              :rules="[rules.email, rules.duplicate]"
                              outlined
                              :hint="
                              countEmails(textInput[Object.keys(menus).indexOf(name)])
                              "
                              autofocus
                           ></v-textarea>
                           <v-switch
                              v-if="textInput[Object.keys(menus).indexOf(name)] != ''"
                              v-model="switchValue[Object.keys(menus).indexOf(name)]"
                              :label="
                              includeOrExclude(
                                 switchValue[Object.keys(menus).indexOf(name)]
                              )
                              "
                              class="mt-0 pt-0"
                           ></v-switch>
                        </v-form>
                     </v-card-text>
                  </v-card>
               </v-tab-item>

               <v-tab-item>
                  <v-card>
                     <!-- <v-card-title>Select timeout length</v-card-title> -->
                     <v-card-text>
                        <v-select persistent-hint
                           hint="Timeout Length"
                           :items="dropdownItems"
                           v-model="dropdownValue"
                           item-text="text"
                           item-value="value"
                           @change="dropdownChange()"
                        >
                        </v-select>
                     </v-card-text>
                  </v-card>
               </v-tab-item>

               <v-tab-item>
                  <v-card>
                     <v-card-text>
                        <v-textarea autofocus outlined
                           label="Enter dashboard names separated by commas"
                           :rows="textAreaRows"
                           class="py-0 pr-4"
                           :rules="[rules.list, rules.duplicate]"
                           :hint="countDashboards(biDashboards)"
                           v-model="biDashboards"
                           @keyup="textareaChanged"
                        ></v-textarea>
                     </v-card-text>
                  </v-card>
               </v-tab-item>

               <v-tab-item>
                  <v-card>
                     <v-card-text>
                        <v-textarea autofocus outlined
                           label="Enter dashboard names separated by commas"
                           :rows="textAreaRows"
                           class="py-0 pr-4"
                           :rules="[rules.list, rules.duplicate]"
                           :hint="countDashboards(lsDashboards)"
                           v-model="lsDashboards"
                           @keyup="textareaChanged"
                        ></v-textarea>
                     </v-card-text>
                  </v-card>
               </v-tab-item>

               <v-tab-item>
                  <v-card>
                     <v-card-text>
                        <v-autocomplete clearable dense hide-selected
                           placeholder="select a landing page..."
                           :items="landingPageItems"
                           v-model="landingPage"
                           @change="landingPageChanged"
                        ></v-autocomplete>
                     </v-card-text>
                  </v-card>
               </v-tab-item>

               <v-tab-item>
                  <v-card>
                     <v-card-text>
                        <v-autocomplete clearable  multiple small-chips deletable-chips
                           placeholder="select an app..."
                           :counter="appItems.length"
                           :items="appItems"
                           v-model="apps"
                           @change="appsChanged"
                        ></v-autocomplete>
                     </v-card-text>
                  </v-card>
               </v-tab-item>
            </v-tabs-items>
         </v-tabs>

         <v-card-actions class="pt-0">
         <div class="flex-grow-1"></div>
         <v-btn
            text
            class="px-0"
            color="blue darken-1"
            @click="restorePreviousState()"
            :disabled="!enableReset"
            >Reset
         </v-btn>
         <v-btn
            text
            @click="saveButtonAction()"
            class="px-0"
            color="blue darken-1"
            :disabled="!valid || initInvalid"
            >Save
         </v-btn>
         </v-card-actions>
      </v-card>
   </v-container>
</template>

<script>
export default {
   name: "RdPreferences",

   props: {
      value: {
         type: Object
      },

      textAreaRows: {
         type: Number,
         default: 3
      },

      appItems: {
         type: Array,
         default: () => []
      }
   },

   data: () => ({
      menus: {
         // home: [],   //V250324: Based on Aref's version
         dashboards: [],
         campaigns: [],
         assets: [],
         crm: [],
         marketintel: [],
         contactservice: [],
         csvprocessor: [],
         settings: [],
      },

      tab: null,
      enableReset: false,
      outputObject: {
         menus: {},
         timeout: null,
      },
      textInput: ["", "", "", "", "", "", "", ""],
      valid: true,
      initInvalid: true,
      switchValue: [true, true, true, true, true, true, true, true],
      enableValue: [true, false, false, false, false, false, false, false],
      dropdownItems: [
         { text: "30 Minutes", value: 30 },
         { text: "1.0 Hour", value: 60 },
         { text: "1.5 Hours", value: 90 },
         { text: "2.0 Hours", value: 120 },
         { text: "2.5 Hours", value: 150 },
         { text: "3.0 Hours", value: 180 },
      ],
      dropdownValue: 30,

      rules: {
         email: (value) => {
            const EMAIL_PATTERN = /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            var tempArr = value.split(",");
            if (value === "") return true;
            for (var i = 0; i < tempArr.length; i++) {
               if (!EMAIL_PATTERN.test(tempArr[i])) {
                  return "Invalid email or format given";
               }
            }
            return true;
         },

         duplicate: (value) => {
            var tempArr = value.split(",");
            let valuesAlreadySeen = [];
            for (var i = 0; i < tempArr.length; i++) {
               let searchVal = tempArr[i].trim();
               if (valuesAlreadySeen.indexOf(searchVal) !== -1) {
                  return "Duplicate value detected";
               }
               valuesAlreadySeen.push(searchVal);
            }
            return true;
         },
         list: value => {
            let result = true;
            if (value.trim()) {
               value.split(',').forEach(val => {
                  const v = val.trim();
                  if (!v) {
                     result = false;
                     return;
                  }
               });
            }
            return result || 'Value is invalid!';
         }
      },

      biDashboards: '',
      lsDashboards: '',
      //V250324: Based on Aref's version, removed '/home' from the following array
      landingPageItems: [ '/dashboards', '/module/objectives', '/contact-service'], //'/home', 
      landingPage: '',
      apps: []
   }),

   methods: {
      countEmails(stringIn) {
         if (stringIn === "") return "Number of emails: " + 0;
         var tempArr = stringIn.split(",");
         return "Number of emails: " + tempArr.length.toString();
      },

      saveButtonAction() {
         this.outputObject.menus = {};
         for (var item in this.menus) {
            if (this.enableValue[Object.keys(this.menus).indexOf(item)] === true) {
               if (this.outputObject.menus.length > 0) {
                  this.outputObject.menus.length = 0;
               }
               if (this.menus[item].length > 0) {
                  //clear array so that items don't get duplicated
                  this.menus[item].length = 0;
               }
               if (this.textInput[Object.keys(this.menus).indexOf(item)] === "") {
                  // put empty array if text box is empty
                  this.outputObject.menus[item] = [];
                  continue;
               }
               if (this.switchValue[Object.keys(this.menus).indexOf(item)] === false)
                  this.menus[item].push(-1);
               var tempArr =
                  this.textInput[Object.keys(this.menus).indexOf(item)].split(","); //convert comma separated string into array
               for (var j = 0; j < tempArr.length; j++) {
                  this.menus[item].push(tempArr[j]);
               }
               this.outputObject.menus[item] = this.menus[item];
            }
         }

         this.outputObject.timeout = this.dropdownValue;

         if (this.biDashboards.trim())
            this.outputObject.biDashboards = this.biDashboards.trim().split(',');
         else
            this.outputObject.biDashboards = [];

         if (this.lsDashboards.trim())
            this.outputObject.lsDashboards = this.lsDashboards.trim().split(',');
         else
            this.outputObject.lsDashboards = [];

         if (this.landingPage)
            this.outputObject.landingPage = this.landingPage;

         if (this.apps.length)
            this.outputObject.apps = [...this.apps];

         this.enableReset = false;
         this.initInvalid = true;
         this.$emit("input", this.outputObject);
         this.$emit("change", this.outputObject);
         //alert(JSON.stringify(this.outputObject));
      },

      includeOrExclude(switchValueCheck) {
         if (switchValueCheck == true) return "Include";
         else return "Exclude";
      },

      isDisabled(propName) {
         //  alert('propName='+propName + '=>' + (propName == "dashboards"));
         if (propName == this.landingRoute) return true;
         else return false;
      },

      convertToTitle(initTitle) {
         if (initTitle === "home") return "Home";
         else if (initTitle === "dashboards") return "Dashboards";
         else if (initTitle === "campaigns") return "Campaigns";
         else if (initTitle === "assets") return "Assets";
         else if (initTitle === "crm") return "CRM";
         else if (initTitle === "marketintel") return "Market Intelligence";
         else if (initTitle === "contactservice") return "List Service";
         else if (initTitle === "csvprocessor") return "CSV Processor";
         else if (initTitle === "settings") return "Settings";
         else return initTitle;
      },

      convertParentToInternal() {
         // put parent data in this.menus
         for (var item in this.value.menus) {
         if (item in this.menus) {
            this.menus[item] = this.value.menus[item];
            this.enableValue[Object.keys(this.menus).indexOf(item)] = true;
            var stringSum = "";
            if (this.value.menus[item][0] === -1) {
               this.switchValue[Object.keys(this.menus).indexOf(item)] = false;
               for (var i = 1; i < this.value.menus[item].length; i++) {
               if (i === this.value.menus[item].length - 1) {
                  stringSum = stringSum + this.value.menus[item][i];
               } else {
                  stringSum = stringSum + this.value.menus[item][i] + ",";
               }
               }
            } else {
               this.switchValue[Object.keys(this.menus).indexOf(item)] = true;
               for (var j = 0; j < this.value.menus[item].length; j++) {
               if (j === this.value.menus[item].length - 1) {
                  stringSum = stringSum + this.value.menus[item][j];
               } else {
                  stringSum = stringSum + this.value.menus[item][j] + ",";
               }
               }
            }
            this.textInput[Object.keys(this.menus).indexOf(item)] = stringSum;
         }
         }
         for (item in this.menus) {
            if (item in this.value.menus)
               this.enableValue[Object.keys(this.menus).indexOf(item)] = true;
         }
         this.dropdownValue = 30;
         for (var k = 0; k < this.dropdownItems.length; k++) {
            if (this.value.timeout === this.dropdownItems[k].value) {
               this.dropdownValue = this.value.timeout;
               break;
            }
         }

         if (this.value.biDashboards && this.value.biDashboards.length)
            this.biDashboards = this.value.biDashboards.join(',');
         if (this.value.lsDashboards && this.value.lsDashboards.length)
            this.lsDashboards = this.value.lsDashboards.join(',');
         if (this.value.landingPage)
            this.landingPage = this.value.landingPage;
         if (this.value.apps?.length)
            this.apps = [...this.value.apps];
      },

      restorePreviousState() {
         if (
            confirm(
               "The form will reset and your unsaved changes will be lost. Do you still want to continue?"
            )
         ) {
            this.convertParentToInternal();
            this.enableReset = false;
            this.initInvalid = true;
            this.$forceUpdate();
         }
      },

      dropdownChange() {
         this.enableReset = true;
         this.initInvalid = false;
      },

      countDashboards(stringIn) {
         if (stringIn === "") return "Number of dashboards: 0";
         var tempArr = stringIn.split(",");
         return "Number of dashboards: " + tempArr.length.toString();
      },

      textareaChanged() {
         this.enableReset = true;
         this.initInvalid = false;
      },

      landingPageChanged() {
         this.enableReset = true;
         this.initInvalid = false;
      },

      appsChanged() {
         this.enableReset = true;
         this.initInvalid = false;
      }
   },

   computed: {
      landingRoute() {
         // alert('landingRoute='+this.$store.getters.deployment.landingRoute);
         return this.$store.getters.deployment.landingRoute;
      },
   },

   watch: {
      //  value: {
      //    handler() {
      //       this.convertParentToInternal();
      //    },
      //    deep: true,
      //  },
      textInput: {
         handler() {
            this.enableReset = true;
            this.initInvalid = false;
         },
         deep: true,
      },

      enableValue: {
         handler() {
            this.enableReset = true;
            this.initInvalid = false;
         },
         deep: true,
      },

      switchValue: {
         handler() {
            this.enableReset = true;
            this.initInvalid = false;
         },
         deep: true,
      },

      landingRoute: {
         immediate: true,
         handler() {
            // alert('in watch for landingRoute: val=' + val);
            this.convertParentToInternal();
         }
      }
   },

   created() {
      //  this.convertParentToInternal();
   },
};
</script>
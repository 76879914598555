<template>
<!-- HISTORY: no-version
   V240724.1: Added Tags item to the menu.
   V240327.1: Included Actions menu to permittedItems based on the user policy.
   V240126.1: Made Actions and Triggers menus available to developers as well (moved the related code to getPermittedItems()).
   V230713.1: Added Predictives item to the menu.
   V230309.1: Added Triggers item to the menu.
   V230221.1: Added Actions item to the menu.
   V221107.1: Added Settings item to the menu.
   03/31/22(B0.3): Added Remote Accesses item to the menu.
   03/17/22(B0.2): Built menu items based on the user permissions (policies) + Synched icons and routes w/Aref's.
   10/14/20(B0.1): File was created.
-->
   <nav>
      <v-navigation-drawer permanent
         class="lighten-2 full-height px-0"
         style="background-color: #f6f6f6; height: 91vh; position:sticky; top:50px; z-index:2"
         mini-variant-width="60"
         width="220"
         :mini-variant="miniVariant"
      >
         <v-list class="py-0">
            <v-divider color="white"></v-divider>
            <v-list-item :dense="true">
               <v-list-item-icon>
                  <v-icon class="grey--text">list</v-icon>
               </v-list-item-icon>
               <v-list-item-content>
                  <v-list-item-title class="body-2 grey--text font-italic">Data Services</v-list-item-title>
               </v-list-item-content>
            </v-list-item>
         </v-list>

         <v-divider calss="my-2" dark></v-divider>

         <v-list class="py-0" flat>
            <!-- <v-divider color="white"></v-divider> -->
            <v-list-item v-for="item in permittedItems"
               link
               :key="item.title"
               :to="getLink(item.route)"
            >
               <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
               </v-list-item-icon>
               <v-list-item-content>
                  <v-list-item-title class="caption font-weight-bold" >{{ item.title }}</v-list-item-title>
               </v-list-item-content>
            </v-list-item>
            <v-divider color="white"></v-divider>
            <v-list-item @click="toggleMenu()">
               <v-list-item-icon>
                  <v-icon>more_horiz</v-icon>
               </v-list-item-icon>
            </v-list-item>
         </v-list>
      </v-navigation-drawer>
   </nav>
</template>

<script>
// import { IamSettings } from '../models/iam-settings.js';
import { developersAccess } from '../mixins/cs-apps.js';

function _compareItems (item1, item2) {
   return item1.seq > item2.seq ? 1 : -1;
}

export default {
   props: {
      baseRoute: {
         type: String,
         required: true
      },
      debug: {
         type: Boolean,
         default: false
      },
      miniVariant: {
         type: Boolean,
         default: false
      }
   },

   data() {
      return {
         items: [
            { seq: 1, icon: 'dashboard', title: 'Dashboards', route: 'dashboards', policy: 'contact-dashboards-list' },
            { seq: 2, icon: 'file_download', title: 'Imports', route: 'imports', policy: 'contact-import-list' },
            { seq: 3, icon: 'file_upload', title: 'Exports', route: 'exports', policy: 'contact-export-list' },
            { seq: 4, icon: 'sell', title: 'Tags / Updates', route: 'tags', policy: 'contact-tags-list' },//bookmark
            { seq: 5, icon: 'delete', title: 'Deletes', route: 'deletes', policy: 'contact-deletes-list' },
            { seq: 6, icon: 'sort', title: 'Indexes', route: 'indexes', policy: 'contact-indexes-list' },
            { seq: 7, icon: 'vpn_key', title: 'Remote Accesses', route: 'access', policy: 'contact-access-list' },
            { seq: 8, icon: 'search', title: 'Search & Analyze', route: 'documents', policy: 'contact-offers-list' },
            { seq: 9, icon: 'settings', title: 'Settings', route: 'settings', policy: 'contact-settings-view' },
            { seq: 10, icon: 'smart_toy', title: 'Predictives', route: 'predictives', policy: 'contact-predictives-list?' },//robot
            { seq: 11, icon: 'connect_without_contact', title: 'Actions', route: 'actions', policy: 'contact-action-list' },
            { seq: 12, icon: 'flash_on', title: 'Triggers', route: 'triggers', policy: 'contact-trigger-list?' }
            // { icon: 'settings', title: 'Configuration', route: 'config', policy: 'contact-config-list' }
         ],
         permittedItems: [],
         // settings: new IamSettings(this.debug),
         myStyle: 'background-color: #f6f6f6; height: 91vh; overflow-y:unset; position:sticky; top:0',
         jwt: {},
         developers: developersAccess()
      }
   },

   computed: {
      userPolicies() {
         // alert('in computed.userPolicies(): val=' + JSON.stringify(this.$store.getters.user.policies));
         return this.$store.getters.user.policies;
      },

      //V240124.1
      token() {
         return this.$store.getters.token;
      },
   },

   watch: { 
      userPolicies: {
         immediate: true,
         deep: true,
         handler () {
            this.getPermittedItems();
         }
      },

      //V240124.1
      token: {
         handler (val) {
            this.jwt = JSON.parse(Buffer.from(val.split('.')[1], 'base64'));
            this.getPermittedItems();
         }
      }
   },

   methods: {
      getLink(route) {
         return "/" + this.baseRoute + "/" + route;
      },

      toggleMenu() {
         this.$emit('more-click');
      },

      //V240124.1
      getPermittedItems() {
         if (this.token && this.userPolicies) {
            const permittedItems = this.items.filter(item => this.userPolicies.includes(item.policy));
            if (this.$store.getters.user.isPowerAdmin) {
               permittedItems.push(this.items.find(item => item.route === 'predictives'));
            }

            if (this.$store.getters.user.isPowerAdmin || this.developers.includes(this.$store.getters.user.email)) {
               // permittedItems.push(this.items.find(item => item.route === 'actions'));
               permittedItems.push(this.items.find(item => item.route === 'triggers'));
            }

            this.permittedItems = permittedItems.sort(_compareItems);
         }
      }

      // onScroll(e) {
      //    this.myStyle = 'background-color: #f6f6f6; height: 91vh; overflow-y:unset; position:sticky; top:' + e.target.scrollTop + 'px';
      //    alert(this.myStyle);
      // }
   },
   
   created() { }
}
</script>

<style scope>
   .v-list .v-list-item--active {
      color:#ff6633;
      background: #f6f6f6;
  }
</style>
<template>
<!-- TODO:
   - Break expanded row into 2 col
-->
<!-- HISTORY:
   V250318.1: Fixed a bug that prevented update btn to be clicked.
   V250311.1: Added SFTP & PGP tab to allow creation of SFTP tags and updates + Added edit/update and delete feature for SFTP +
      Allowed a file with 'purl' and '_id' headers to be accepted as well.
   V240802.1: Passed true as the 2nd param to getImportNames() in order to get completed imports only.
   V240731.2: Added matchedRecords and modifiedRecords to the table + Removed purl-related templates left from copy/paste +
      Modified refreshClicked() to refresh stats as well.
   V240729.1: removed dedupColumns from the API request and UI + Displayed importIDs to importNames for the expanded rows +
      Added fileDelimiter and importIDs to searchFields.
   V240726.1: Added Update action/feature.
   V240725.2: Fixed some bugs + Set dedup columns based on the import's header.
   V240724.1: 1st version/release.
-->
<!-- const schema = {
   tagName: Joi.string().min(1).max(50).required(),
   filePath: Joi.string().required(),
   fileDelimiter: Joi.string().min(1).max(1),
   dedupColumns: Joi.array().min(1).max(5).items(Joi.string()),
   debug: Joi.boolean(),
   importIDs: Joi.array().items(Joi.objectId()).min(0).max(5) 
   //dedupColumns: Joi.array().min(0).items(Joi.string()),  //was removed
}; -->
<v-container fluid class="px-3 py-3">
   <v-card>
      <v-card-title class="pl-2">
         <h1 class="title font-weight-bold grey--text darken-4 pl-2" style="color:#757575 !important">
            <v-icon class="pr-1">sell</v-icon>
            <span>Tags / Updates</span>
         </h1>
         <div class="flex-grow-1"></div>
         <div>
            <v-btn v-if="tags?.length"
               x-small
               class="mr-2 mt-1"
               color="gray darken-1"
               :disabled="btnRefreshTagsDisabled || !tags.length"
               @click="refreshClicked()"
            >REFRESH{{tagsDisabledDuration ? ' in ' + tagsDisabledDuration + ' sec' : ''}}
               <v-icon right dark>refresh</v-icon>
            </v-btn>
            <v-btn v-if="canCreateTag"
               x-small
               class="mr-2 mt-1"
               color="gray darken-1"
               :disabled="loadingTags"
               @click="updateClicked()"
            >NEW UPDATE
               <v-icon right dark>add</v-icon>
            </v-btn>
            <v-btn v-if="canCreateTag"
               x-small
               class="mr-2 mt-1"
               color="gray darken-1"
               :disabled="loadingTags"
               @click="newTagClicked()"
            >NEW TAG
               <v-icon right dark>add</v-icon>
            </v-btn>
         </div>
      </v-card-title>

      <v-card-text class="py-0">
         <bt-filter-wrapper-with-panel
            :closed-on-load="true"
            :fields="searchFields"
            :included-tabs="['standard']"
            :is-admin="jwt.pa"
            :max="searchFields.length"
            :preselected-fields="[]"
            :should-init="shouldInitFilterDefiner2"
            :std-field-values="searchFieldsValues"
            v-model="filter2"
            @filter-change="filterChanged2"
         ></bt-filter-wrapper-with-panel>
      </v-card-text>

      <v-card-text>
         <v-data-table dense fixed-header show-expand single-expand
            class="elevation-1"
            item-key="_id"
            :footer-props="{
               itemsPerPageOptions: [5, 10, 20],
               showFirstLastPage: true
            }"
            :headers="headers"
            :hide-default-footer="tagsStats.count <= 5"
            :items="tags"
            :items-per-page="5"
            :loading="loadingTags"
            :loading-text="$t('loading-text')"
            :no-data-text="$t('no-data-text', { value: 'tags' })"
            :no-results-text="$t('no-results-text', { value: 'tags' })"
            :options.sync="options"
            :search="search"
            :server-items-length="tagsStats.count"
            @update:expanded="tagExpanded"
         >
            <template v-slot:[`item.updateColumns`]="{ item }">
               {{ item.updateColumns?.length ? item.updateColumns.join(', ') : '' }}
            </template>
            <template v-slot:[`item.creationDate`]="{ item }">
               {{ formatDate(item.creationDate, true) }}
            </template>
            <template v-slot:[`item.status`]="{ item }">
               <v-tooltip top color="black">
                  <template v-slot:activator="{ on, attrs }">
                     <span v-bind="attrs" v-on="on">
                        <v-icon :color="getIcon(item.status).color"
                        >{{getIcon(item.status).icon}}</v-icon>
                     </span>
                  </template>
                  <span>{{item.status}}</span>
               </v-tooltip>
            </template>
            <template v-slot:[`item.processedRecords`]="{ item }">
               <v-tooltip v-if="item.processedRecords"
                  top color="black"
               >
                  <template v-slot:activator="{ on, attrs }">
                     <span v-bind="attrs" v-on="on">
                        {{formatNumber(item.processedRecords)}}
                     </span>
                  </template>
                  <span>Processing Time: {{calculateProcessingTime(item.lastActivityDate, item.startDate, 0)}}</span>
                  <br>
                  <span>Processing Speed: {{calculateProcessingSpeed(item.processedRecords, item.lastActivityDate, item.startDate, 0)}} Rec/Hr</span>
               </v-tooltip>
               <span v-else>0</span>
            </template>
            <template v-slot:[`item.matchedRecords`]="{ item }">
               {{ formatNumber(item.matchedRecords) }}
            </template>
            <template v-slot:[`item.modifiedRecords`]="{ item }">
               {{ formatNumber(item.modifiedRecords) }}
            </template>
            <template v-slot:[`item.startDate`]="{ item }">
               {{ formatDate(item.startDate, true) }}
            </template>
            <template v-slot:[`item.lastActivityDate`]="{ item }">
               {{ formatDate(item.lastActivityDate, true) }}
            </template>

            <template v-slot:[`item.action`]="{ item }">
               <span v-if="item.sftp?.sftpCredentials?.host">
                  <v-tooltip top color="black">
                     <template v-slot:activator="{ on, attrs }">
                        <v-icon small
                           class="pr-2"
                           v-on="on"
                           v-bind="attrs"
                           :disabled="!canEditTag"
                           color="red"
                           @click="editTagClicked(item)"
                        >edit</v-icon>
                     </template>
                     <span>Click to edit SFTP settings...</span>
                  </v-tooltip>
                  <v-tooltip top color="black">
                     <template v-slot:activator="{ on, attrs }">
                        <!-- <v-icon v-if="item.status.indexOf('sftp') === 0" -->
                        <!-- mdi-cloud-remove -->
                        <v-icon small
                           v-on="on"
                           v-bind="attrs"
                           :disabled="!canDeleteTag"
                           color="red"
                           @click="deleteTag(item)"
                        >cloud_off</v-icon>
                     </template>
                     <span>Click to delete the tag/update...</span>
                  </v-tooltip>
               </span>
            </template>

            <template v-slot:expanded-item="{ item }">
               <td :colspan="item.sftp ? 7 : 11" class="py-2" valign="top" dense>
                  <ul>
                     <li>
                        <span class="expanded-header">File Path: </span>
                        <span class="expanded-content">{{item.filePath}}</span>
                     </li>
                     <li>
                        <span class="expanded-header">File Delimiter: </span>
                        <span class="expanded-content">{{getDelimiterDesc(item.fileDelimiter)}}</span>
                     </li>
                     <li>
                        <span class="expanded-header">Import Names: </span>
                        <span class="expanded-content">{{item.importNames}}</span>
                     </li>
                     <li>
                        <span class="expanded-header">Debug: </span>
                        <span class="expanded-content text-capitalize">{{item.debug}}</span>
                     </li>
                     <li>
                        <span class="expanded-header">DB Name: </span>
                        <span class="expanded-content text-capitalize">{{item.databaseName}}</span>
                     </li>
                     <li>
                        <span class="expanded-header">Recovery Count: </span>
                        <span class="expanded-content">{{item.recoveryCount}}</span>
                     </li>
                     <li>
                        <span class="expanded-header">Recovery Error: </span>
                        <span class="expanded-content">{{item.recoveryError}}</span>
                     </li>
                     <li>
                        <span class="expanded-header">ID: </span>
                        <span class="expanded-content text-capitalize">{{item._id}}</span>
                     </li>
                  </ul>
               </td>
               <td colspan="4" class="py-2" valign="top" dense v-if="item.sftp">
                     <li>
                        <span class="expanded-header">SFTP Host: </span>
                        <span class="expanded-content">{{item.sftp.sftpCredentials.host}}</span>
                     </li>
                     <li v-if="item.sftp.sftpPath">
                        <span class="expanded-header">SFTP Path: </span>
                        <span class="expanded-content">{{item.sftp.sftpPath}}</span>
                     </li>
                     <li v-if="item.sftp.sftpCredentials.port">
                        <span class="expanded-header">SFTP Port: </span>
                        <span class="expanded-content">{{item.sftp.sftpCredentials.port}}</span>
                     </li>
                     <li>
                        <span class="expanded-header">SFTP Username: </span>
                        <span class="expanded-content">{{item.sftp.sftpCredentials.username}}</span>
                     </li>
                     <li>
                        <span class="expanded-header">Has PGP Credentials: </span>
                        <span class="expanded-content">{{item.sftp.pgpCredentials ? true : false}}</span>
                     </li>
               </td>
            </template>

            <template v-slot:[`body.append`] v-if="tagsStats.count">
               <tr class="font-weight-bold">
                  <td colspan="6">Totals:</td>
                  <td align="left">{{formatNumber(tagsStats.processedTotal)}}</td>
                  <td align="left">{{formatNumber(tagsStats.matchedTotal)}}</td>
                  <td align="left">{{formatNumber(tagsStats.modifiedTotal)}}</td>
               </tr>
            </template>
         </v-data-table>
      </v-card-text>
   </v-card>

   <v-dialog no-click-animation persistent
      max-width="1200px"
      v-model="dialogTag"
   >
      <v-card flat>
         <v-card-title class="title grey--text darken-4 font-weight-bold pb-2"
         >
            <div v-if="isEdit">Editing {{currName}}:</div>
            <div v-else>{{`Creating a New ${action === 'add' ? 'Tag' : 'Update'}`}}</div>
         </v-card-title>

         <v-card-text
            class="pb-0"
            :loading="loadingNewTag"
         >
            <div v-if="errMsg"
               class="pb-4 red--text font-weight-bold"
            >{{errMsg}}
            </div>

            <div v-if="action === 'add' && sftpTagsCount >= maxSftpCount" class="pb-3">
               <v-icon color="warning">warning_amber</v-icon>
               <span class="pl-2 font-italic font-weight-bold">You have exceeded the allowed limit of {{maxSftpCount}} SFTP tags.</span>
            </div>
            <div v-if="action === 'update' && sftpUpdatesCount >= maxSftpCount" class="pb-3">
               <v-icon color="warning">warning_amber</v-icon>
               <span class="pl-2 font-italic font-weight-bold">You have exceeded the allowed limit of {{maxSftpCount}} SFTP updates.</span>
            </div>

            <v-tabs
               class="elevation-2"
               background-color="grey lighten-2 accent-4"
               slider-color="black"
               v-model="tab"
            >
               <!-- <v-tab :class="!this.isGeneralFormValid || this.errMsg ? 'red--text' : ''"> -->
               <v-tab v-show="!isEdit"
                  :class="isGeneralFormValid ? '' : 'red--text'"
               >General</v-tab>
               <v-tab
                  :class="formData.isSftpValid ? '' : 'red--text'"
                  :disabled="formData.isFileSelected || (action === 'add' ? sftpTagsCount >= maxSftpCount : sftpUpdatesCount >= maxSftpCount)"
               >SFTP & PGP</v-tab>

               <v-tabs-items v-model="tab">
                  <v-tab-item v-show="!isEdit"><!-- General -->
                     <v-form lazy-validation
                        ref="generalForm"
                        v-model="isGeneralFormValid"
                     >
                        <v-card flat tile>
                           <v-card-text>
                                 <v-row class="pb-2">
                                    <v-col xs="12" sm="12" md="4" class="py-2">
                                       <v-text-field v-if="action === 'add'"
                                          autofocus persistent-hint required
                                          v-model="formData.tagName"
                                          ref="tagName"
                                          hint="* Tag Name"
                                          placeholder="enter a name for the tag or upload a file"
                                          :counter="50"
                                          :maxlength="50"
                                          :rules="[rules.required, rules.duplicate]"
                                       ></v-text-field>
                                       <v-autocomplete v-else
                                          chips clearable deletable-chips multiple persistent-hint small-chips single-line
                                          class="custom-autocomplete pt-4"
                                          ref="updateColumns"
                                          :counter="formData.updateColumnHeaders.length"
                                          :items="formData.updateColumnHeaders"
                                          hint="* Update Columns"
                                          :placeholder="`${formData.updateColumnHeaders.length ? 'select one or more columns for update' : 'upload a file or set up an SFTP'}`"
                                          :rules="[rules.requiredArray]"
                                          v-model="formData.updateColumns"
                                       ></v-autocomplete>
                                    </v-col>
                                    <v-col xs="12" sm="12" md="2" class="py-3">
                                       <v-select persistent-hint hide-selected required
                                          ref="fileDelimiter"
                                          v-model="formData.fileDelimiter"
                                          hint="* File Delimiter"
                                          :items="delimiterItems"
                                          :rules="[rules.required]"
                                       ></v-select>
                                    </v-col>
                                    <v-col xs="12" sm="12" md="6" class="pt-4 pb-2">
                                          <!-- :preselected-items="chart.lastDropdownSelections" -->
                                          <!-- @switch-changed="dropdownSwitchChanged" -->
                                       <v-autocomplete chips clearable deletable-chips multiple persistent-hint small-chips single-line
                                          class="custom-autocomplete"
                                          :counter="dropdownData.length"
                                          :items="dropdownData"
                                          hint="Imports: up to 5"
                                          placeholder="select upto 5 imports"
                                          :rules="[rules.tooManyImports]"
                                          v-model="formData.importIDs"
                                       ></v-autocomplete>
                                    </v-col>
                                 </v-row>
                                 <v-row v-if="formData.previewHeaders.length" class="pt-0">
                                    <v-col cols="12" class="pt-0">
                                       <v-expansion-panels focusable multiple
                                          v-model="panel"
                                       >
                                          <v-expansion-panel>
                                             <v-expansion-panel-header><strong>File Preview</strong></v-expansion-panel-header>
                                             <v-expansion-panel-content class="pt-2 px-0">
                                                <v-data-table dense fixed-header
                                                   class="elevation-1"
                                                   item-key="_mfi_seq"
                                                   :footer-props="{
                                                      itemsPerPageOptions: [5, 10],
                                                      showFirstLastPage: true,
                                                      disableItemsPerPage: true
                                                   }"
                                                   :headers="formData.previewHeaders"
                                                   :hide-default-footer="formData.previewItems.length <= 5"
                                                   :items="formData.previewItems"
                                                   :items-per-page="5"
                                                   :no-data-text="$t('no-data-text', { value: 'records' })"
                                                ></v-data-table>
                                             </v-expansion-panel-content>
                                          </v-expansion-panel>
                                       </v-expansion-panels>
                                    </v-col>
                                 </v-row>
                           </v-card-text>
                        </v-card>
                     </v-form>
                  </v-tab-item>

                  <v-tab-item> <!-- SFTP and PGP -->
                     <div v-if="!isEdit" class="pl-5 pt-3">
                        <v-icon color="warning">warning_amber</v-icon>
                        <span class="pl-2 font-italic font-weight-bold">Please note that you can only create {{maxSftpCount}} SFTP {{action === 'add' ? 'tags' : 'updates'}}. Currently you have {{action === 'add' ? sftpTagsCount : sftpUpdatesCount}}.</span>
                     </div>
                     <bt-sftp-and-pgp
                        :is-edit="isEdit"
                        :email="jwt.email"
                        v-model="formData.sftp"
                        @change="sftpChanged"
                     ></bt-sftp-and-pgp>
                  </v-tab-item>
               </v-tabs-items>
            </v-tabs>
         </v-card-text>

         <v-card-actions class="mr-1 pr-5">
            <v-switch v-if="jwt.au && !isEdit"
               class="mx-0 my-0 pl-5 pt-3 pb-0"
               label="Debug"
               v-model="formData.debug"
            ></v-switch>
            <div class="flex-grow-1"></div>
            <v-btn text
               class="pl-0 pr-3"
               color="blue darken-1"
               @click="cancelClicked"
            >Cancel</v-btn>
            <v-btn v-if="isEdit"
               text
               class="pl-0"
               color="blue darken-1"
               :disabled="!this.formData.isSftpValid || !isFormDataChanged"
               @click="editTag"
            >Update</v-btn>
            <span v-else>
               <import-file-upload tagUpload
                  :notActive="notActive"
                  :noUpload="formData.hasSftp"
                  :isCancelled="isCancelled"
                  @file-selected="setTagName"
                  @uploaded="saveTag"
               />
            </span>
         </v-card-actions>
      </v-card>
   </v-dialog>
</v-container>
</template>

<script>
import BtFilterWrapperWithPanel from './BtFilterWrapperWithPanel.vue';
import ImportFileUpload from './ImportFileUpload.vue';
import BtSftpAndPgp from './BtSftpAndPgp.vue';
import { addSeconds, differenceInHours, differenceInMinutes, differenceInSeconds, format, parseISO } from "date-fns";
import { APIService } from '../services/cs-api-service.js';
import { BtHelpers } from '../services/bt-helpers.js';
import { hasOwn, mergeArrays } from '../mixins/bt-mixin.js';

const NAME = 'CsTags';
const TABS = { general: 0, sftp: 1 };

class FormData {
   constructor(initData) {
      this.name = '';
      this.updateColumns = [];
      this.fileDelimiter = ',';
      this.importIDs = [];
      this.debug = false;
      this.previewHeaders = [];
      this.previewItems = [];
      this.updateColumnHeaders = [];
      this.isFileSelected = false;
      this.hasSftp = false;
      this.isSftpValid = true;
      this.sftp = initData.sftp || {};
   }
}

export default {
   name: NAME,

   components: {
      BtFilterWrapperWithPanel,
      ImportFileUpload,
      BtSftpAndPgp
   },

   props: {
      debug: {
         type: Boolean,
         default: false
      },

      isActualEndpoint: {
         type: Boolean,
         default: true
      }
   },

   data() {
      return {
         jwt: {},
         apiService: null,
         tab: null,
         formData: new FormData({}),
         tags: [],
         rules: {
            required: value => !!value || "Value is required!",
            requiredArray: value => !!value.length || "Value is required!",
            duplicate: value => this.tags.filter(t => t.name === value).length === 0 || 'Value is duplicate!',
            tooManyImports: value => value.length <= 5 || `Not more than 5 Imports can be selected!`
         },
         headers: [
            { text: 'Tag Name', value: 'tagName', align: 'left', sortable: true },
            { text: 'Update Columns', value: 'updateColumns', align: 'left', sortable: true },
            { text: 'Creation', value: 'creationDate', align: 'left', sortable: true },
            { text: 'Creator', value: 'creator', align: 'left', sortable: true },
            { text: 'Status', value: 'status', align: 'left', sortable: true },
            { text: 'Processed', value: 'processedRecords', align: 'left', sortable: true },
            { text: 'Matched', value: 'matchedRecords', align: 'left', sortable: true },
            { text: 'Modified', value: 'modifiedRecords', align: 'left', sortable: true },
            { text: 'Start', value: 'startDate', align: 'left', sortable: true },
            { text: 'Last Activity', value: 'lastActivityDate', align: 'left', sortable: true },
            { text: 'Actions', value: 'action', align: 'right', sortable: false }
         ],
         delimiterItems: [
            { text: 'Comma', value: ',' },
            { text: 'Tab', value: '\t' },
            { text: 'Pipe', value: '|' }
         ],
         search: '',
         tdHeader: 'font-weight-bold font-italic',
         tdContent: 'font-italic',
         tagsDisabledDuration: 0,
         btnRefreshTagsDisabled: true,
         loadingTags: false,
         loadingNewTag: false,
         dialogTag: false,
         isGeneralFormValid: false,
         options: {},
         shouldInitFilterDefiner2: false,
         filter2: {
            standard: [{ $match: {} }]
         },
         searchFields: [
            { text: 'Tag Name', value: 'tagName', type: 'string', isIndexed: true },
            { text: 'Update Columns', value: 'updateColumns', type: 'string', isIndexed: true },
            { text: 'File Delimiter', value: 'fileDelimiter', type: 'string', isIndexed: true },
            { text: 'Import IDs', value: 'importIDs', type: 'string', isIndexed: true },
            { text: 'Status', value: 'status', type: 'string', isIndexed: true },
            { text: 'Processed Records', value: 'processedRecords', type: 'number', isIndexed: true },
            { text: 'Matched Records', value: 'matchedRecords', type: 'number', isIndexed: true },
            { text: 'Modified Records', value: 'modifiedRecords', type: 'number', isIndexed: true },
            { text: 'Creation Date', value: 'creationDate', type: 'date', isIndexed: true },
            { text: 'Start Date', value: 'startDate', type: 'date', isIndexed: true },
            { text: 'Last Activity Date', value: 'lastActivityDate', type: 'date', isIndexed: true }
         ],
         searchFieldsValues: {},
         btHelpers: null,
         panel: [],
         errMsg: '',
         isCancelled: null,
         fileHeaders: [],
         tagsStats: {},
         lastImport: null,
         dedupColumns: null,
         dropdownData: [],
         action: null,
         reservedColumns: [],
         maxSftpCount: 2,
         sftpTagsCount: -1,
         sftpUpdatesCount: -1,
         lastImportUpdatableHeaders: [],
         isEdit: false,
         currTag: {},
         currName: '',
         isFormDataChanged: false
      }
   },

   computed: {
      token() {
         return this.$store.getters.token;
      },

      notActive() {
         const isReady = this.isGeneralFormValid && !this.errMsg && this.formData.isSftpValid;
         return !isReady;
      },

      canCreateTag() {
         return this.$store.getters.user.policies.includes('contact-tags-create');
      },

      canEditTag() {
         return this.$store.getters.user.policies.includes('contact-tags-update');
      },

      canDeleteTag() {
         return this.$store.getters.user.policies.includes('contact-tags-delete');
      }
   },

   watch: {
      token() {
         this.init();
         this.nextAction();
      },

      options: {
         handler (val) {
            if (val.sortBy.length > 0) {
               const sort = {};
               sort[val.sortBy[0]] = val.sortDesc[0] ? -1 : 1;
               this.filter2.sort = sort;
            }
            this.getTags();
         }
      },

      tab: {
         handler (val) {
            if (val === TABS.general) {
               setTimeout(() => {
                  if (this.action === 'add')
                     this.$refs?.tagName?.focus();
                  else
                     this.$refs?.updateColumns?.focus();
               }, 10);
            }
         }
      }
   },

   methods: {
      log(msg) {
         if (this.debug)
            console.log(`-----${NAME} V250311.1 says => ${msg}`);
      },

      logout() {
         this.$router.push('/');
      },

      formatNumber(number) {
         return number ? new Intl.NumberFormat().format(number) : '0';
      },

      isFormatValid(value) {
         let result;
         if (value === 'yyyyMMddTHHmmzzz')
            result = true;
         else if (value.length >= 6) {
            const formatParts = value.split(' ');
            if (formatParts.length <= 3) {
               result = this.isDateFormatValid(formatParts[0]);
               if (result && formatParts.length > 1) {
                  const tz = formatParts.length === 3 ? formatParts[2] : null;
                  result = this.isTimeFormatValid(formatParts[1], tz);
               }
            }
         }

         return result;
      },

      isDateFormatValid(date) {
         const dateParts = date.includes('/') ? date.split('/') : (date.includes('-') ? date.split('-') : date.split('.'));
         if (dateParts.length === 3) {
            const dmy = {};
            dateParts.forEach(part => {
               if (part === 'd' || part === 'dd')
                  dmy.d = true;
               else if (part === 'M' || part === 'MM' || part === 'MMM' || part === 'MMMM')
                  dmy.m = true;
               else if (part === 'yy' || part === 'yyyy')
                  dmy.y = true;
            });

            return dmy.d && dmy.m && dmy.y;
         } else
            return false;
      },

      isTimeFormatValid(time, tz) {
         const timeParts = time.split(':');
         if (timeParts.length > 1) {
            const h12 = timeParts[0] === 'h' || timeParts[0] === 'hh';
            const h24 = timeParts[0] === 'H' || timeParts[0] === 'HH';
            if (h12 || h24) {
               if (h12) {
                  if (tz != 't' && tz != 'tt')
                     return false;
               } else if (tz != null)
                  return false;
            } else
               return false;

            if (timeParts[1] != 'm' && timeParts[1] != 'mm')
               return false;
         }

         if (timeParts.length === 3)
            return timeParts[2] === 's' || timeParts[2] === 'ss';
         else
            return false;
      },


      async init() {
         try {
            this.tags = [];

            if (this.token) {
               this.jwt = JSON.parse(Buffer.from(this.token.split('.')[1], 'base64'));
               this.log('in init(): jwt=' + JSON.stringify(this.jwt));
               this.apiService = new APIService(this.jwt, this.token, this.debug, this.isActualEndpoint);
               this.btHelpers = new BtHelpers(this.token, this.isActualEndpoint, this.debug);
               this.dropdownData = await this.btHelpers.getImportNames(100, true);  //V240802
               // alert('in newTagClicked(): dropdownData=' + JSON.stringify(this.dropdownData));
               this.searchFieldsValues = {
                  fileDelimiter: [
                     { text: 'Comma', value: ',' },
                     { text: 'Tab', value: '\t' },
                     { text: 'Pipe', value: '|' }
                  ],
                  importIDs: this.dropdownData,
                  status: ['waiting','processing','sftp','sftp-processing','completed','recovery','rejected']
               };
               this.tagsStats = await this.getTagsStats(this.filter2);
               this.shouldInitFilterDefiner2 = true;
            } else
               this.jwt = {};
         } catch (error) {
            alert('Exception while parsing token: ' + error.message);
         }
      },

      async getTagsStats(filter) {
         this.loadingTags = true;
         let result = await this.apiService.getTagsStats(filter);
         this.loadingTags = false;
         if (result.logout)
            this.logout();

         return result.message ? { count: 0 } : result.data;
      },

      async refreshClicked() {
         this.tagsStats = await this.getTagsStats(this.filter2);
         await this.getTags();
      },

      async getTags() {
         // alert(`in getTags(): filter2=${JSON.stringify(this.filter2)}`);
         this.loadingTags = true;
         this.btnRefreshTagsDisabled = true;
         this.tags = [];
         let result = await this.apiService.getTags(this.filter2, this.options.page, this.options.itemsPerPage);
         // alert('in getTags(): result=' + JSON.stringify(result));
         if (result.logout)
            this.logout();
            
         if (!result.message) {
            this.tags = result.data;
         }

         if (this.tags.length || result.message) {
            this.tagsDisabledDuration = 10;
            setTimeout(() => {
               this.btnRefreshTagsDisabled = false;
            }, 10000);

            const setIntervalTags = setInterval(() => {
               if (this.tagsDisabledDuration > 0)
                  this.tagsDisabledDuration--;
               else
                  clearInterval(setIntervalTags);
            }, 1000);
         }

         this.loadingTags = false;
      },

      getIcon(status) {
         switch (status) {
            case 'waiting':
               return { color: 'grey', icon: 'schedule' };
            case 'processing':
               return { color: 'blue', icon: 'hourglass_empty' };
            case 'sftp':
               return { color: 'grey lighten-2', icon: 'cloud' };  //mdi-cloud-outline
            case 'sftp-processing':
               return { color: 'blue', icon: 'cloud_off' }; //mdi-cloud-off-outline
            case 'completed':
               return { color: 'green', icon: 'done' };
            case 'recovery':
               return { color: 'orange', icon: 'restore' };
            case 'rejected':
               return { color: 'red', icon: 'clear' };
            default:
               return { color: '', icon: '' };
         }
      },

      getDelimiterDesc(delimiter) {
         return this.delimiterItems.filter(d => d.value === delimiter)[0].text;
      },

      formatDate(date, withTime) {
         if (date) {
            const formatteddate = format(parseISO(date), 'M/d/yyyy h:mm:ss a');
            if (withTime) return formatteddate;
            else return formatteddate.split(' ')[0];
         }
      },

      calculateProcessingTime(dateEnded, dateStarted, extraSeconds) {
         const isoEnded = parseISO(dateEnded);
         const isoStarted = addSeconds(parseISO(dateStarted), extraSeconds || 0);
         const h = differenceInHours(isoEnded, isoStarted);
         const min = differenceInMinutes(isoEnded, isoStarted);
         const m = min - h * 60;
         const s = differenceInSeconds(isoEnded, isoStarted) - h * 3600 - m * 60;
         let diff = '';
         if (h) diff = h + 'h ';
         if (min) diff += m + 'm ';
         diff += s + 's';
         return diff;
      },

      calculateProcessingSpeed(processedRecs, dateEnded, dateStarted, extraSeconds) {
         const cntPerHour = this.formatNumber(Math.round(processedRecs * 3600 / differenceInSeconds(parseISO(dateEnded), addSeconds(parseISO(dateStarted), extraSeconds || 0))));
         // alert(`processedRecs=${processedRecs}\ndateEnded=${dateEnded}\ndateStarted=${dateStarted}\ncntPerHour=${cntPerHour}`);
         return cntPerHour;
      },

      tagExpanded(items) {
         let item;
         if (!items.length)
            return;
         else {
            item = items[0];
            if (item.importNames || !item.importIDs.length)
               return;
         }

         const names = [];
         item.importIDs.forEach(id => {
            const imprt = this.dropdownData.find(d => d.value === id);
            if (imprt)
               names.push(imprt.text);
         });

         item.importNames = names.join(', ');

      },

      async newTagClicked() {
         this.action = 'add';
         await this.addUpdateClicked();

         if (this.lastImport && this.sftpTagsCount === -1) {
            const filter = {
               standard: [{ 
                  $match: {
                     "tagName": { $exists: true, $ne: "" },
                     "sftp.sftpCredentials.host": { $exists: true, $ne: "" }
                  }
               }]
            };

            const tagsStats = await this.getTagsStats(filter);
            this.sftpTagsCount = tagsStats?._id ? tagsStats.count : 0;
         }
      },
      
      async updateClicked() {
         this.action = 'update';
         await this.addUpdateClicked();

         if (this.lastImport && this.sftpUpdatesCount === -1) {
            const filter = {
               standard: [{ 
                  $match: {
                     "tagName": { $exists: false },
                     "sftp.sftpCredentials.host": { $exists: true, $ne: "" }
                  }
               }]
            };

            const tagsStats = await this.getTagsStats(filter);
            this.sftpUpdatesCount = tagsStats?._id ? tagsStats.count : 0;

            if (this.sftpUpdatesCount < this.maxSftpCount) {
               const lastImportHeaders = this.lastImport.header.map(item => ({ text: item, value: item.toLowerCase() }));

               this.lastImportUpdatableHeaders = [];
               lastImportHeaders.forEach(header => {
                  if (!this.reservedColumns.includes(header.value))
                     this.lastImportUpdatableHeaders.push(header.text);
               });

               if (lastImportHeaders.length) {
                  this.validateFieldsAgainstHeaders(this.dedupColumns, lastImportHeaders);
               }
            }
         }
      },

      async addUpdateClicked() {
         this.dialogTag = true;
         setTimeout(() => {
            this.tab = TABS.general;
         }, 10);
         this.errMsg = '';
         this.isGeneralFormValid = false;
         if (!this.lastImport) {
            this.lastImport = await this.btHelpers.getLastImport();
            if (!this.lastImport) {
               alert("Sorry, something went wrong and we couldn't obtain the last import record!");
               return;
            } else {
               this.dedupColumns = [];
               this.lastImport.dedupColumns.forEach(col => {
                  const header = this.lastImport.header.find(h => h.toLowerCase() === col);
                  if (header)
                     this.dedupColumns.push(header);
                  else
                     this.errMsg += `Couldn't find '${col}' dedup column in the last import's header! `
               });

               this.reservedColumns = mergeArrays(
                  ['_id','importid', 'purl', 'basepurl','purlnumber','deduphash','events','rownumber','location'],
                  this.lastImport.purlColumns,
                  this.lastImport.dedupColumns
               );
            }
         }

         this.panel = [];
         this.formData = new FormData(this.tags[0] || {});
         this.isCancelled = false;
      },

      cancelClicked() {
         this.isCancelled = true;
         this.cancelTag();
      },

      cancelTag() {
         this.isEdit = false;
         this.tab = TABS.general;
         this.dialogTag = false;
         this.formData = new FormData({});
      },

      async setTagName(arg) {
         // alert(`in setTagName(): arg=${JSON.stringify(arg)}`);
         this.formData.isFileSelected = true;
         this.tab = TABS.general;
         this.formData.previewHeaders = [];
         this.formData.updateColumnHeaders = [];

         if (hasOwn(arg, 'fileName')) {
            if (!this.formData.tagName?.trim()) {
               this.formData.tagName = arg.fileName.replace(/.csv/i, '').replace(/.txt/i, '').replace(/.zip/i, '');
            }

            if (hasOwn(arg, 'fileData') && arg.fileData.data && arg.fileData.data.length) {
               this.fileHeaders = [...arg.fileData.data[0]];
               arg.fileData.data[0].forEach(header => {
                  this.formData.previewHeaders.push({
                     text: header,
                     value: header.toLowerCase(),
                     align: 'left',
                     sortable: false
                  });
                  if (!this.reservedColumns.includes(header.toLowerCase()))
                     this.formData.updateColumnHeaders.push(header);
               });
               // alert('previewHeaders=' + JSON.stringify(this.formData.previewHeaders));
               if (this.formData.previewHeaders.length) {
                  this.validateFieldsAgainstHeaders(this.dedupColumns, this.formData.previewHeaders);
               }

               if (arg.fileData.data.length > 1) {
                  let len = arg.fileData.data.length;

                  // Removing the last line in case it wasn't read in full.
                  if (arg.fileData.data[len - 1].length < this.formData.previewHeaders.length)
                     len--;

                  for (let i = 1; i < len; i++) {
                     const record = arg.fileData.data[i];
                     const item = {};
                     for (let j = 0; j < this.formData.previewHeaders.length; j++) {
                        item[this.formData.previewHeaders[j].value] = record[j];
                     }
                     item._mfi_seq = i;
                     this.formData.previewItems.push(item);
                  }
                  // alert('previewItems=' + JSON.stringify(this.formData.previewItems));
               }

               if (hasOwn(arg.fileData, 'meta') && hasOwn(arg.fileData.meta, 'delimiter') && arg.fileData.meta.delimiter)
                  this.formData.fileDelimiter = arg.fileData.meta.delimiter;
            } else {
               this.fileHeaders = [];
            }
         }
      },

      validateFieldsAgainstHeaders(fields, headers) {
         const purlAndId = headers.filter(h => ['_id', 'purl'].includes(h.value));
         if (purlAndId.length === 2)
            return;

         const result = {
            validFields: [],
            invalidFields: []
         };

         fields.forEach(element => {
            const field = element.trim();
            if (field) {
               const fld = headers.find(f => f.value === field.toLowerCase());
               if (fld)
                  result.validFields.push(fld.value);
               else
                  result.invalidFields.push(field);
            }
         });

         if (result.invalidFields.length)
            this.errMsg += `'${result.invalidFields.join(', ')}' do${result.invalidFields.length === 1 ? 'es' : ''} not exist in the headers!`;
         else if (!result.validFields.length)
            this.errMsg += `No columns have been specified!`;
         else
            this.errMsg = '';
      },

      async saveTag(arg) {
         if (this.notActive) return;

         // alert('in saveTag(): formData=' + JSON.stringify(this.formData));
         this.loadingNewTag = true;

         const postData = {
            fileDelimiter: this.formData.fileDelimiter,
            importIDs: this.formData.importIDs,
            debug: this.formData.debug
         };

         if (this.action === 'add')
            postData.tagName = this.formData.tagName;
         else
            postData.updateColumns = this.formData.updateColumns;

         if (hasOwn(arg, 'fileName'))
            postData.filePath = arg.fileName;
         
         if (this.formData.hasSftp && this.formData.isSftpValid) {
            postData.sftp = this.formData.sftp;
         }

         this.log('in saveTag(): postData=' + JSON.stringify(postData));
         const result = await this.apiService.createTag(postData);
         this.loadingNewTag = false;
         if (result.logout)
            this.logout();
         else if (!result.message) {
            this.$emit('snackbar-event', `'${postData.tagName}' was saved.`);
            this.tagsStats.count++;
            this.getTags();
            this.cancelTag(false);
         }
      },

      async filterChanged2(filter) {
         // alert('in filterChanged2(): filter=' + JSON.stringify(filter) + '\noptions=' + JSON.stringify(this.options));
         this.filter2 = filter;
         this.tagsStats = await this.getTagsStats(this.filter2);
         this.nextAction();
      },

      async nextAction() {
         const currOptions = JSON.stringify(this.options);
         const newOptions = JSON.parse(currOptions);
         newOptions.page = 1;
         if (JSON.stringify(newOptions) === currOptions)
            await this.getTags();
         else
            this.options = newOptions;
      },

      sftpChanged(hasSftp, isSftpValid) {
         this.isFormDataChanged = true;
         this.formData.hasSftp = hasSftp;
         this.formData.isSftpValid = isSftpValid;
         this.formData.updateColumnHeaders = hasSftp ? this.lastImportUpdatableHeaders : [];
      },

      async deleteTag(item) {
         const name = item.name ? `'${item.name}' tag` : `update #${item._id}`;
         if (confirm(`Are you sure you want to DELETE ${name}?`)) {
            this.loadingTags = true;
            this.log('in deleteTag(): item=' + JSON.stringify(item));
            const result = await this.apiService.deleteTag(item._id);
            if (result.logout)
               this.logout();
            else if (!result.message) {
               const ind = this.tags.indexOf(item);
               this.tags.splice(ind, 1);
               this.$emit('snackbar-event', `${name} was deleted.`);
            }
            this.loadingTags = false;
         }
      },

      async editTagClicked(item) {
         this.isFormDataChanged = false;
         this.currTag = JSON.parse(JSON.stringify(item));
         this.currName = item.name ? `'${item.name}' Tag` : `Update #${item._id}`;
         this.errMsg = null;
         this.panel = [];
         this.formData = new FormData(this.currTag);
         this.isCancelled = false;
         this.dialogTag = true;
         setTimeout(() => {
            this.isEdit = true;
            this.tab = TABS.sftp;
         }, 10);
      },

      async editTag() {
         if (!this.formData.isSftpValid) return;   //

         this.loadingNewTag = true;
         this.errMsg = null;
         this.log('in editTag(): sftp=' + JSON.stringify(this.formData.sftp));
         let result = await this.apiService.updateTag(this.currTag._id, this.formData);
         this.loadingNewTag = false;
         if (result.logout)
            this.logout();
         else if (!result.message) {
            this.$emit('snackbar-event', `${this.currName} was updated.`);
            const updatedTag = this.tags.find(t => t._id === this.currTag._id);               
            updatedTag.status = result.data.status;
            updatedTag.sftp = result.data.sftp;
            updatedTag.lastActivityDate = result.data.lastActivityDate;
            this.cancelTag();
         }
      }
   },

   created() {
      this.init();
   },
}
</script>

<style scoped>
.expanded-header {
   font-style: italic;
   font-weight: bold;
}
.v-expansion-panel-header, .v-expansion-panel-header--active {
   padding: 0 16px;
   min-height: 32px !important;
}
div >>> .v-expansion-panel-content__wrap {
   padding: 8px 16px 16px !important;
}
.v-text-field >>> .v-label {
    font-size: 20px;
    color: black;
    font-weight: 400 !important;
}
.custom-autocomplete .v-label {
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  font-family: 'Arial', sans-serif; /* Change to your desired font family */
  font-size: 116px; /* Change to your desired font size */
  color: #999; /* Change to your desired font color */
  transition: 0.2s ease-in-out;
  pointer-events: none;
}

.custom-autocomplete .v-input--is-focused .v-label,
.custom-autocomplete .v-input--has-state .v-label {
  font-size: 116px; /* Keep the label the same size */
  color: #999; /* Keep the label color same as placeholder */
  transform: translateY(-50%); /* Keep it inline with input */
}

.custom-autocomplete input {
  font-family: 'Arial', sans-serif; /* Change to your desired font family */
  font-size: 116px; /* Change to your desired font size */
  color: #000; /* Change to your desired input text color */
}

.custom-autocomplete .v-chip__content {
  font-family: 'Arial', sans-serif; /* Match font family */
  font-size: 116px; /* Match font size */
  color: #000; /* Match chip content color */
}
</style>
